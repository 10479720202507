import React, { useContext } from 'react'
import { ReactSVG } from 'react-svg'

import { CustomLink } from '@/components'
import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Image } from '@/uikit'

import * as style from './styles.module.css'

interface Props {
  name: string
  address?: string
  readonly contact_image: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
  social_media: GatsbyTypes.Maybe<
    ReadonlyArray<
      Pick<
        GatsbyTypes.MarkdownRemarkFrontmatterBodyContact_social_media,
        'alternative_text' | 'name' | 'link' | 'open_in_new_tab'
      > & { readonly icon: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'id' | 'extension' | 'publicURL'>> }
    >
  >
}

export const Location: React.FC<Props> = ({ name, address, social_media, contact_image }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  return (
    <div className="max-w-xl bg-white rounded-2xl z-0 dark:bg-body-bg1-dm relative">
      <Image src={contact_image} alt="Team" className="rounded-2xl relative" width="570" height="410" />
      <div className="absolute -mt-10 rounded-lg bg-body-bg1-dm dark:bg-body-bg1-dm py-3.5 px-7 left-1/2 top-full transform -translate-x-1/2">
        <p className=" text-white pb-2 font-inter font-semibold text-lg">{name}</p>
        <p
          dangerouslySetInnerHTML={{ __html: address }}
          className=" text-white-clear text-opacity-70 -mt-1 pb-2 text-left leading-small font-inter font-light font-base"
        />
        <ul className="hidden sm:flex">
          {social_media &&
            social_media.map((mediaIcon) => (
              <li className="mx-2 mt-2 first:ml-0" key={mediaIcon.icon?.id}>
                <CustomLink type="external" url={mediaIcon.link} newTab={mediaIcon.open_in_new_tab || false}>
                  <div className={style.lightModeIconFill}>
                    {mediaIcon.icon?.publicURL && (
                      <ReactSVG
                        src={mediaIcon.icon.publicURL}
                        aria-label={mediaIcon.name || 'icon'}
                        className="transition-transform duration-500 hover:scale-125 transform"
                      />
                    )}
                  </div>
                </CustomLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
