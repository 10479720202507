import React from "react";
import "@fontsource/dm-mono";
import "@fontsource/inter";
import { ThemeProvider, LayoutProvider } from "@/context";
import { combineComponents } from "@/helpers/combineComponents";

const MainLayoutProvider = combineComponents(
  ThemeProvider,
  LayoutProvider,
);

export const wrapRootElement = ({ element }) => (
  <MainLayoutProvider>{element}</MainLayoutProvider>
);
