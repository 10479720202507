import React, { FunctionComponent, useEffect, useState } from 'react'
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'

import { FacebookIconComponent, TwitterIconComponent, LinkedinIconComponent } from '@/icons'

interface Props {
  title: string
  excerpt: string
}

export const SharePost: FunctionComponent<Props> = ({ title, excerpt }) => {
  const [currentURL, setCurrentUrl] = useState('')
  const isWindow = typeof window !== 'undefined'

  useEffect(() => {
    if (isWindow) {
      setCurrentUrl(window.location.href)
    }
  }, [isWindow])
  return (
    <div className="mt-17.5 md:mt-25 mx-7.5 md:mx-0 border-t dark:border-t border-text-80 border-opacity-20 dark:border-white-clear dark:border-opacity-20 pt-3.5 pb-5 flex justify-between print:hidden">
      <p className="border-none bg-none font-ilisarniq text-opacity-80 text-text dark:text-white-clear text-xs font-semibold leading-14">
        Share
      </p>
      <div className="flex w-22 justify-between">
        <FacebookShareButton
          url={currentURL}
          quote={excerpt}
          className="transition-transform duration-500 hover:scale-125 transform"
        >
          <FacebookIconComponent />
        </FacebookShareButton>
        <TwitterShareButton
          url={currentURL}
          title={title}
          via={excerpt}
          className="transition-transform duration-500 hover:scale-125 transform"
        >
          <TwitterIconComponent />
        </TwitterShareButton>
        <LinkedinShareButton
          url={currentURL}
          title={title}
          summary={excerpt}
          className="transition-transform duration-500 hover:scale-125 transform"
        >
          <LinkedinIconComponent />
        </LinkedinShareButton>
      </div>
    </div>
  )
}
