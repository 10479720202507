import { graphql } from 'gatsby'
import React from 'react'

import { CustomLink } from '@/components'
import { Image, Content, Subheading, ButtonPrimmary, ButtonSecondary } from '@/uikit'

export const WeAreFocusedOnSection: React.FC<GatsbyTypes.WeAreFocusedOnSectionFragment> = ({
  focused_on_links,
  alternative_accent_text,
  heading,
  lead,
  accent,
}) => {
  return (
    <div className="relative py-16.5 ">
      <Content className="max-w-930">
        <div className="flex justify-center items-center flex-col">
          <div className="xmd:text-center mb-10">{heading && <Subheading bold>{heading}</Subheading>}</div>
          <div className=" md:max-w-740 xmd:text-center font-inter font-light text-lg text-black-lighter dark:text-text-70-dm leading-semiBig md:leading-semiBig">
            {lead}
          </div>
          {focused_on_links && (
            <div className=" flex flex-col md:flex-row items-center justify-center mt-15 w-full">
              {focused_on_links &&
                focused_on_links.map((link, index) => (
                  <div className="w-auto mx-0 md:mx-4 my-4 md:my-0" key={link.title}>
                    <CustomLink url={link.url} type={link.type} newTab={link.new_tab}>
                      {index === 0 ? (
                        <ButtonPrimmary>{link.title}</ButtonPrimmary>
                      ) : (
                        <ButtonSecondary>{link.title}</ButtonSecondary>
                      )}
                    </CustomLink>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Content>
      <div className="ani ani-mask-left absolute right-0 -top-10 md:block hidden max-w-350 ">
        <Image src={accent} alt={alternative_accent_text || ''} />
      </div>
    </div>
  )
}

export const weAreFocusedOnSectionQuery = graphql`
  fragment WeAreFocusedOnSection on MarkdownRemarkFrontmatterBody {
    heading
    lead
    alternative_accent_text
    focused_on_links {
      url
      title
      new_tab
      type
    }
    accent {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
  }
`
