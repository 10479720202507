export const formatDate: (date: Date, langFormat?: string) => string = (date: Date, langFormat = 'en-US') =>
  new Date(date)
    .toLocaleDateString(langFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '.')

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getMonthAndYear = (date: Date): string =>
  `${new Date(date).toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  })} `

export const getCurrentMonthAndYear = (): string =>
  new Date().toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  })
export function isSameMonth(date: Date, month: string): boolean {
  const dateLabel = new Date(date).toLocaleString('en-US', {
    month: 'long',
  })
  return dateLabel === month
}

export const getAllMonthNames: <T>(array: T[], propertyName: keyof T) => string[] = (array, propertyName) => {
  const allRecords = array.reduce((recordA: string[], recordB: T) => {
    const dateLabel = new Date(recordB[propertyName]).toLocaleString('en-US', {
      month: 'long',
    })
    return [...recordA, dateLabel]
  }, [])
  return Array.from(new Set(allRecords))
}
