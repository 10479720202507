import { Link } from 'gatsby'
import React, { useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'

import Arrow from './components/arrow'
import * as styles from './styles.module.css'

type Props = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBodyBoxes,
  'slug' | 'description' | 'heading' | 'icon_alternative_text'
> & {
  readonly icon_dark_mode: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'extension' | 'publicURL'>>
  readonly icon: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'extension' | 'publicURL'>>
}

export const StepBox: React.FC<Props> = ({
  slug,
  heading,
  icon,
  icon_dark_mode,
  icon_alternative_text,
  description,
}) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === themes.dark

  return (
    <li className="ani-child ani-up max-w-full lg:max-w-270 xl:max-w-290 mb-7.5 lg:mb-0 flex-1 dark:bg-body-bg1-dm bg-white rounded-lg flex flex-col items-start ">
      <Link
        to={slug}
        key={heading}
        className="flex-1 transform-gpu group w-full flex flex-col font-light text-text-80 bg-white dark:bg-body-bg1-dm block px-7.5 py-7.5 rounded-lg duration-500 hover:shadow-lg dark:hover:bg-body-bg2-dm"
      >
        <div className="flex justify-between items-center w-full">
          <p className=" font-ilisarniq font-bold  text-blue-dark dark:text-blue-light text-xl leading-6">{heading}</p>
          <Arrow isDark={isDark} className="transition transform group-hover:translate-x-2" />
        </div>

        <img
          className={`${styles.icon} my-6 duration-300 group-hover:opacity-100 opacity-20`}
          src={isDark ? icon_dark_mode?.publicURL : icon?.publicURL}
          alt={icon_alternative_text}
          width="24"
          height="24"
        />
        <p className="font-inter text-base leading-6 font-light text-text dark:text-text-dm">{description}</p>
      </Link>
    </li>
  )
}
