import { generateOptionsFromValues } from '@/components'

type Jobs = ReadonlyArray<{
  readonly node: Pick<GatsbyTypes.GreenhouseJob, 'title' | 'updated_at' | 'gh_Id'> & {
    readonly location: GatsbyTypes.Maybe<Pick<GatsbyTypes.GreenhouseJobLocation, 'name'>>
    readonly departments: GatsbyTypes.Maybe<ReadonlyArray<Pick<GatsbyTypes.GreenhouseDepartment, 'name'>>>
  }
}>

type Job = {
  readonly node: Pick<GatsbyTypes.GreenhouseJob, 'title' | 'updated_at' | 'gh_Id'> & {
    readonly location: GatsbyTypes.Maybe<Pick<GatsbyTypes.GreenhouseJobLocation, 'name'>>
    readonly departments: GatsbyTypes.Maybe<ReadonlyArray<Pick<GatsbyTypes.GreenhouseDepartment, 'name'>>>
  }
}
const generateOptionsFromJobs: (jobs: Jobs) => { label: string; value: string }[] = (jobs) => {
  const DEFAULT_OPTIONS_STATE = [
    {
      label: 'All',
      value: '',
    },
  ]

  const uniqOptions = jobs.reduce((reducedArray: Job[], currentJob) => {
    const isDepartmentInReducedArray = reducedArray.find(
      (jobInReducedArray) =>
        jobInReducedArray.node.departments &&
        currentJob.node.departments &&
        jobInReducedArray.node.departments[0].name === currentJob.node.departments[0].name
    )
    return isDepartmentInReducedArray ? reducedArray : [...reducedArray, currentJob]
  }, [])

  if (uniqOptions.length === 0) return DEFAULT_OPTIONS_STATE

  return [
    ...DEFAULT_OPTIONS_STATE,
    ...generateOptionsFromValues(uniqOptions.map((job) => (job.node.departments ? job.node.departments[0].name : ''))),
  ]
}

export default generateOptionsFromJobs
