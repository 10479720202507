import React, { useContext } from 'react'

import { themes } from '@/consts'
import { toggleTheme, ThemeContext } from '@/context'
import { SunIcon, MoonIcon } from '@/icons'

import * as styles from './styles.module.css'

type Iprops = {
  hideIcon: boolean
  id?: string
}

export const ThemeToggler: React.FC<Iprops> = ({ hideIcon, id }) => {
  const {
    state: { theme },
    dispatch,
  } = useContext(ThemeContext)

  const ToggleIcon = theme === themes.dark ? MoonIcon : SunIcon

  return (
    <div className="flex justify-center items-center">
      {!hideIcon && <ToggleIcon className="mr-2" />}
      <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
        <label
          htmlFor={id}
          id={`${id}-label`}
          aria-label="Toggle Menu"
          className="block overflow-hidden rounded-full w-12 h-7 bg-text-50-bg border dark:border-white dark:border-opacity-10  cursor-pointer dark:bg-white-bg-weak dark:bg-opacity-30"
        >
          <input
            type="checkbox"
            name="toggle"
            id={id}
            aria-labelledby={`${id}-label`}
            className={`${styles.checkbox} duration-500 transition-transform transform hover:scale-125 mx-1 absolute block w-3,5 h-3.5 rounded-full bg-orange border-text-bg focus:outline-none border-4 p-1.8 dark:mt-0.2 mt-0.2 appearance-none cursor-pointer dark:border-black-toggler `}
            checked={theme === themes.dark}
            onChange={() => dispatch(toggleTheme())}
          />
        </label>
      </div>
    </div>
  )
}
