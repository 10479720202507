import TurndownService from 'turndown'

export const htmlToMarkdownConverter = (snippet: string): string => {
  const turndownService = new TurndownService()

  // parse html (otherwise it adds nested <p> tags and related errors) if not doing SSR
  if (typeof DOMParser !== 'undefined') {
    const doc = new DOMParser().parseFromString(snippet, 'text/html')
    snippet = doc.documentElement.textContent
  }

  const markdown = turndownService.turndown(`${snippet}`)
  return markdown
}
