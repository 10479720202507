import { Link, graphql } from 'gatsby'
import React from 'react'

import { EmployeeCard } from '@/components'
import { ButtonSecondary, Label, Subheading, Content, Text, Image } from '@/uikit'

export const TeamSection: React.FC<GatsbyTypes.TeamSectionFragment> = ({
  team_accent,
  alternative_accent_text,
  alternative_image_text,
  button_text,
  button_slug,
  employees,
  heading,
  team_image,
  team_image_dark_mode,
  label,
  lead,
}) => {
  return (
    <div id="team" className="flex justify-center pt-65 pb-65 md:pb-6">
      <div className="overflow-hidden max-w-1920">
        <Content className="max-w-930">
          <div className="text-center mb-2.5">
            <Label>{label}</Label>
          </div>
          <div className="text-center mb-10 md:7.5">{heading && <Subheading bold>{heading}</Subheading>}</div>
          <div className="text-center mb-20 md:mb-25">
            <Text>{lead}</Text>
          </div>
        </Content>
        <Content>
          <div className="flex justify-center items-center flex-col">
            <ul
              data-ani-stagger="50"
              data-ani-threshold="0"
              className="ani-group w-full flex flex-wrap justify-between items-start z-10 relative"
            >
              {employees && employees?.map((employee, i) => <EmployeeCard {...employee} key={i} />)}
            </ul>
          </div>
        </Content>
        <div className="w-full  relative flex flex-col items-center justify-center ">
          {button_slug && (
            <div className="w-full flex justify-center items-center">
              <Link to={button_slug} className="w-270  mb-15 ">
                {button_text && <ButtonSecondary>{button_text}</ButtonSecondary>}
              </Link>
              <div />
            </div>
          )}
          <div className="flex justify-between w-full">
            <div className="ani ani-right relative md:block hidden">
              <Image src={team_accent} alt={alternative_accent_text || ''} />
            </div>
            <div className="ani ani-left dark:hidden w-850 relative -right-28 md:-right-20 md:w-auto">
              <Image src={team_image} alt={alternative_image_text || ''} />
            </div>
            <div className="ani ani-left ani-blur dark:block hidden w-850 relative -right-28 md:-right-20 md:w-auto">
              <Image src={team_image_dark_mode} alt={alternative_image_text || ''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const teamSectionQuery = graphql`
  fragment TeamSection on MarkdownRemarkFrontmatterBody {
    label
    heading
    button_text
    button_slug
    team_accent {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 300, formats: [WEBP])
      }
      extension
      publicURL
    }
    alternative_accent_text
    team_image {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 800, formats: [WEBP])
      }
      publicURL
      extension
    }
    team_image_dark_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 800, formats: [WEBP])
      }
      extension
      publicURL
    }
    alternative_image_text
    lead
    employees {
      alternative_avatar_text
      name
      position
      description
      avatar {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 90, formats: [WEBP])
        }
        publicURL
        extension
      }
    }
  }
`
