import { graphql } from 'gatsby'
import React from 'react'

import { Heading, Text, Content, Image } from '@/uikit'

import * as styles from './styles.module.css'

export const MessageSentSection: React.FC<GatsbyTypes.MessageSentSectionFragment> = ({
  alternative_icon_text,
  header,
  lead,
  message_sent_icon,
}) => {
  return (
    <>
      <Content>
        <div className=" flex flex-col justify-center items-center text-center">
          <div className="mt-14">{header && <Heading>{header}</Heading>}</div>
          <div className=" mt-7.5 max-w-700 ">
            <Text>{lead}</Text>
          </div>
          <div className={` ${styles.imageSize} mt-15 `}>
            <Image src={message_sent_icon} alt={alternative_icon_text || ''} className={styles.imageSize} />
          </div>
        </div>
      </Content>
    </>
  )
}

export const messageSentQuery = graphql`
  fragment MessageSentSection on MarkdownRemarkFrontmatterBody {
    header
    lead
    message_sent_icon {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
      publicURL
      extension
    }
    alternative_icon_text
  }
`
