import { graphql } from 'gatsby'
import React, { useContext } from 'react'

import { StepBox, CustomLink } from '@/components'
import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Heading, Text, ButtonPrimmary, ButtonSecondary, Image, Content } from '@/uikit'

export const Hero: React.FC<GatsbyTypes.HeroFragment> = ({
  accent_primary,
  accent_primary_dark_mode,
  accent_secondary,
  accent_secondary_dark_mode,
  accent_tertiary,
  alternative_accent_text,
  hero_cta_list,
  boxes,
  description,
  header,
}) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDarkTheme = theme === themes.dark

  const renderLinks =
    hero_cta_list &&
    hero_cta_list.map((link, index) => {
      return (
        <div
          className={index === 0 ? ' w-full mb-5 md:mb-0 max-w-240 md:mr-8 ' : ' md:max-w-290 w-full '}
          key={link.title}
        >
          <CustomLink url={link.url} type={link.type} newTab={link.new_tab || false}>
            {index === 0 ? (
              <ButtonPrimmary>{link.title}</ButtonPrimmary>
            ) : (
              <ButtonSecondary>{link.title}</ButtonSecondary>
            )}
          </CustomLink>
        </div>
      )
    })

  return (
    <div className="relative flex flex-col justify-center items-center">
      <Content>
        <div className="flex flex-col jusify-center items-start md:items-center ">
          <div className="flex flex-col items-start justify-center xmd:items-center w-full">
            {header && (
              <div className="z-10 max-w-250 xmd:max-w-xl text-left xmd:text-center mb-9 md:mb-7.5 pt-12 md:pt-36 ">
                <Heading>{header}</Heading>
              </div>
            )}
            {description && (
              <div className=" max-w-lg text-left xmd:text-center  mb-10 md:mb-7">
                <Text>{description}</Text>
              </div>
            )}
          </div>
          <div className="flex flex-wrap flex-col md:flex-row items-center justify-center w-full ">
            {hero_cta_list &&
              hero_cta_list.map((link, index) => (
                <div className="w-auto mx-0 md:mx-4 my-4 md:my-0" key={link.title}>
                  <CustomLink url={link.url} type={link.type} newTab={link.new_tab || false}>
                    {index === 0 ? (
                      <ButtonPrimmary>{link.title}</ButtonPrimmary>
                    ) : (
                      <ButtonSecondary>{link.title}</ButtonSecondary>
                    )}
                  </CustomLink>
                </div>
              ))}
          </div>
          {boxes && (
            <ul className="ani-group w-full flex-col lg:flex-row flex justify-between z-10 py-16 md:py-33 md:max-w-930 ">
              {boxes?.map((box) => (
                <StepBox {...box} key={box?.heading} />
              ))}
            </ul>
          )}
        </div>
      </Content>
      {isDarkTheme ? (
        <>
          <div className="ani ani-right ani-blur absolute top-0 left-0 md:max-w-150 lg:max-w-200 sxl:max-w-max md:block hidden ">
            <Image src={accent_primary_dark_mode} alt={alternative_accent_text || ''} />
          </div>
          <div className="ani ani-left ani-blur absolute top-0 right-0 md:max-w-150 lg:max-w-200 sxl:max-w-max md:block hidden">
            <Image src={accent_secondary_dark_mode} alt={alternative_accent_text || ''} />
          </div>
        </>
      ) : (
        <>
          <div className="ani ani-right ani-blur absolute top-0 left-0 md:max-w-150 lg:max-w-200 sxl:max-w-max md:block hidden ">
            <Image src={accent_primary} alt={alternative_accent_text || ''} />
          </div>
          <div className="ani ani-left ani-blur absolute top-0 right-0 md:max-w-150 lg:max-w-200 sxl:max-w-max md:block hidden">
            <Image src={accent_secondary} alt={alternative_accent_text || ''} />
          </div>
        </>
      )}

      <div className="ani ani-right ani-blur absolute -bottom-28 left-0 md:max-w-150 lg:max-w-200 sxl:max-w-max md:block hidden">
        <Image src={accent_tertiary} alt={alternative_accent_text || ''} />
      </div>
    </div>
  )
}

export const heroQuery = graphql`
  fragment Hero on MarkdownRemarkFrontmatterBody {
    hero_cta_list {
      url
      title
      new_tab
      type
    }
    accent_primary {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    accent_primary_dark_mode {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    accent_secondary {
      childImageSharp {
        gatsbyImageData(width: 330, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    accent_secondary_dark_mode {
      childImageSharp {
        gatsbyImageData(width: 330, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    accent_tertiary {
      childImageSharp {
        gatsbyImageData(width: 170, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    alternative_accent_text
    boxes {
      slug
      description
      heading
      icon_alternative_text
      icon_dark_mode {
        extension
        publicURL
      }
      icon {
        extension
        publicURL
      }
    }
    description
    header
  }
`
