import { getMonthAndYear } from '@/helpers/utils'

type Posts = ReadonlyArray<Post>

type Post = {
  readonly node: Pick<GatsbyTypes.MarkdownRemark, 'id'> & {
    readonly frontmatter: Pick<
      GatsbyTypes.MarkdownRemarkFrontmatter,
      'url' | 'title' | 'author' | 'date_of_publication' | 'post_categories' | 'excerpt'
    > & {
      readonly bp_image_light: GatsbyTypes.Maybe<{
        readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
      }>
      readonly bp_image_dark: GatsbyTypes.Maybe<{
        readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
      }>
    }
  }
}

type AccType = {
  [key: string]: Post[]
}

export const getGroupedAndSlicedPosts: (
  posts: Posts,
  isSortingByDate: boolean,
  maximumElements: number
) => { [key: string]: Post[] } = (posts, isSortingByDate, maximumElements) => {
  return posts.reduce((reducedPosts: AccType, nextPost) => {
    const groupKey = isSortingByDate
      ? getMonthAndYear(nextPost.node.frontmatter.date_of_publication)
      : nextPost.node.frontmatter.title[0].toUpperCase()

    if (!reducedPosts[groupKey]) {
      reducedPosts[groupKey] = []
    }

    // get the current amount of items in the array
    const amountOfResults = Object.values(reducedPosts)
      .map((item) => item.length)
      .reduce((sum, item) => {
        const newSum = sum + item
        return newSum
      }, 0)

    return {
      ...reducedPosts,
      [groupKey]: maximumElements <= amountOfResults ? reducedPosts[groupKey] : reducedPosts[groupKey].concat(nextPost),
      // Stop adding posts if it exceed the amount of possible visible items
    }
  }, {})
}
