import { graphql } from 'gatsby'
import React, { useContext, useCallback, FunctionComponent } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Subheading, Text, Image, Content } from '@/uikit'

import * as styles from './styles.module.css'

type BenefitType = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBodyBenefits,
  'heading' | 'description' | 'alternative_icon_text'
> & {
  readonly icon: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.File, 'extension' | 'publicURL'> & {
      readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
    }
  >
}

export const CareersBenefitsSection: FunctionComponent<GatsbyTypes.CarrersBenefitSectionFragment> = ({
  alternative_image_text,
  benefits,
  header,
  image,
  image_dark_mode,
  image_caption,
  lead,
}) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)

  const isDark = theme === themes.dark

  const renderBenefitBlock = useCallback(
    (benefit: BenefitType) => (
      <div key={benefit.heading} className="relative mb-10 md:mb-20 md:max-w-290 w-full">
        {benefit.icon && (
          <div className={` ${styles.benefitIcon} absolute -top-1`}>
            <Image src={benefit.icon} alt={benefit.alternative_icon_text} className="ani-child ani-left" />
          </div>
        )}
        <div className="pl-11">
          <div className="mb-2.5">
            <b className="dark:text-text-dm leading-medium mb-2.5">{benefit.heading}</b>
          </div>
          <p className="font-inter font-light text-lg text-text-70 dark:text-text-70-dm leading-semiBig">
            {benefit.description}
          </p>
        </div>
      </div>
    ),
    [isDark]
  )

  return (
    <div className="overflow-x-hidden relative mt-65 mb-50 max-w-1250 mx-auto">
      {header || lead ? (
        <Content className="max-w-930 md:mb-20 mb-15.5">
          {header && (
            <div className="mb-8 text-center">
              <Subheading bold>{header}</Subheading>
            </div>
          )}
          {lead && (
            <div className="text-center">
              <Text>{lead}</Text>
            </div>
          )}
        </Content>
      ) : null}
      <Content>
        <div className="relative grid md:grid-cols-3 gap-x-10 justify-center">
          <div className="text-center mb-15 md:mb-0 md:col-span-1 col-span-2">
            {image && (
              <div className="ani ani-up ani-blur dark:hidden block">
                <Image src={image} alt={alternative_image_text || ''} />
              </div>
            )}
            {image_dark_mode && (
              <div className="ani ani-up ani-blur dark:block hidden">
                <Image src={image_dark_mode} alt={alternative_image_text || ''} />
              </div>
            )}
            {image_caption && <h3 className="text-28 font-ilisarniq mt-7">{image_caption}</h3>}
          </div>
          <ul data-ani-stagger="50" className="col-span-2 ani-group md:grid md:grid-cols-2 justify-flex-end z-10">
            {benefits &&
              benefits.map((benefit, i) => {
                return (
                  <li key={`Benefit_${i}`} className="flex justify-end">
                    {renderBenefitBlock(benefit)}
                  </li>
                )
              })}
          </ul>
        </div>
      </Content>
    </div>
  )
}

export const careersBenefitQuery = graphql`
  fragment CarrersBenefitSection on MarkdownRemarkFrontmatterBody {
    header
    lead
    alternative_image_text
    image_caption
    image {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 290, quality: 90, formats: [WEBP])
      }
      extension
      publicURL
    }
    image_dark_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 290, quality: 90, formats: [WEBP])
      }
      extension
      publicURL
    }
    benefits {
      heading
      description
      alternative_icon_text
      icon {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 48, quality: 100)
        }
      }
    }
  }
`
