import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { MarkdownComponent, CustomLink } from '@/components/elements'
import { formatDate } from '@/helpers/utils'
import { Image, ButtonPrimmary, ButtonSecondary } from '@/uikit'

export const CaseStudiesHighlights: React.FC<GatsbyTypes.CaseStudiesHighlightsFragment> = ({
  primary_title,
  list_of_buttons,
}) => {
  const caseStudiesList = useStaticQuery<GatsbyTypes.Last5CaseStudiesQuery>(
    graphql`
      query Last5CaseStudies {
        caseStudies: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/content/case-studies/.*/" }
            frontmatter: { url: { ne: "safety-page" } }
          }
          sort: { fields: frontmatter___date_of_publication, order: DESC }
          limit: 5
        ) {
          edges {
            node {
              id
              frontmatter {
                url
                title
                date_of_publication
                cs_image_light {
                  childImageSharp {
                    gatsbyImageData(width: 530, placeholder: BLURRED, formats: [WEBP])
                  }
                  extension
                  publicURL
                }
                cs_image_dark {
                  childImageSharp {
                    gatsbyImageData(width: 530, placeholder: BLURRED, formats: [WEBP])
                  }
                  extension
                  publicURL
                }
                excerpt
              }
            }
          }
        }
      }
    `
  )
  const { caseStudies } = caseStudiesList || {}
  const latestPost = caseStudies?.edges ? caseStudies.edges[0].node : null

  return (
    <section className=" md:pb-0 md:pt-17.5 py-17.5">
      <div className="max-w-full bg-white dark:bg-body-bg1-dm flex justify-center">
        <div className="max-w-540 lg:max-w-1060 mx-6.2 mt-22 mb-130">
          <div className="mb-16">
            <h2 className="max-w-768 mx-auto my-4 text-text text-center text-3xl md:text-5xl dark:text-white font-ilisarniq font-bold leading-big tracking-tightest">
              {primary_title}
            </h2>
          </div>
          {latestPost && (
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/2 flex-shrink-0">
                <Link to={latestPost ? `/case-studies/${latestPost.frontmatter.url}` : '/'} className="group">
                  <div className="max-w-full flex justify-center">
                    {latestPost.frontmatter.cs_image_light && (
                      <div className="ani ani-blur ani-scale dark:hidden block">
                        <Image
                          src={latestPost.frontmatter.cs_image_light}
                          alt={latestPost.frontmatter.title}
                          className="transition-transform duration-500 hover:scale-105 transform"
                        />
                      </div>
                    )}
                    {latestPost.frontmatter.cs_image_dark && (
                      <div className="ani ani-blur ani-scale dark:block hidden">
                        <Image
                          src={latestPost.frontmatter.cs_image_dark}
                          alt={latestPost.frontmatter.title}
                          className="transition-transform duration-500 hover:scale-105 transform"
                        />
                      </div>
                    )}
                  </div>
                  <h3 className="my-3 font-normal text-text dark:text-white text-24 sm:text-32 leading-38 font-ilisarniq tracking-tightest dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                    {latestPost.frontmatter.title}
                  </h3>
                  <div className="my-4 text-text-70 dark:text-text-70-dm font-inter font-light leading-normal text-lg">
                    {latestPost?.frontmatter?.excerpt && (
                      <MarkdownComponent>{latestPost.frontmatter.excerpt}</MarkdownComponent>
                    )}
                  </div>
                </Link>
                <div className="mt-15 md:mt-8 flex flex-col md:flex-row">
                  {list_of_buttons &&
                    list_of_buttons?.map((link, index) => (
                      <CustomLink type={link.type} url={link.url} newTab={link.new_tab || false} key={link.title}>
                        <div className="w-auto mx-0 md:mx-4 my-4 md:my-0">
                          {index === 0 ? (
                            <ButtonPrimmary>{link.title}</ButtonPrimmary>
                          ) : (
                            <ButtonSecondary>{link.title}</ButtonSecondary>
                          )}
                        </div>
                      </CustomLink>
                    ))}
                </div>
              </div>
              <ul className="hidden lg:flex flex-col gap-y-30 lg:pl-18 xl:pl-110 lg:w-1/2 flex-shrink-0">
                {caseStudies.edges &&
                  caseStudies.edges.slice(1, 5).map(({ node: { id, frontmatter } }) => (
                    <li key={id}>
                      <Link to={`/case-studies/${frontmatter.url}`} className="flex items-center gap-30 group">
                        <div className="flex-shrink-0 flex items-center">
                          {frontmatter.cs_image_light && (
                            <div className="ani ani-blur ani-scale dark:hidden block">
                              <Image
                                src={frontmatter.cs_image_light}
                                alt=""
                                className="h-48 w-72 md:h-24 md:w-36 block transition-transform duration-500 hover:scale-110 transform"
                              />
                            </div>
                          )}
                          {frontmatter.cs_image_dark && (
                            <div className="ani ani-blur ani-scale dark:block hidden">
                              <Image
                                src={frontmatter.cs_image_dark}
                                alt=""
                                className="h-48 w-72 md:h-24 md:w-36 block transition-transform duration-500 hover:scale-110 transform"
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex-initial">
                          <div className="font-semibold text-text dark:text-white text-lg leading-medium dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                            {frontmatter.title}
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const caseStudiesQuery = graphql`
  fragment CaseStudiesHighlights on MarkdownRemarkFrontmatterBody {
    primary_title
    list_of_buttons {
      title
      url
      type
      new_tab
    }
  }
`
