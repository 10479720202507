// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-demo-job-offer-tsx": () => import("./../../../src/pages/demo/job-offer.tsx" /* webpackChunkName: "component---src-pages-demo-job-offer-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-job-offer-tsx": () => import("./../../../src/templates/jobOffer.tsx" /* webpackChunkName: "component---src-templates-job-offer-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

