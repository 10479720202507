import { graphql } from 'gatsby'
import React, { useContext, useMemo, useState, useRef, useCallback } from 'react'
import Slider from 'react-slick'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Blockquote, Image, Container } from '@/uikit'

import Arrow from './components/arrow'
import * as style from './styles.module.css'
import 'slick-carousel/slick/slick.css'

export const TestimonialsSlider: React.FC<GatsbyTypes.TestimonialsSliderFragment> = ({
  heading,
  autoplay,
  slides,
  testimonials_image,
  testimonials_image_dark_mode,
  alternative_image_text,
}) => {
  const [activeSlide, changeActiveSlide] = useState(0)
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const sliderRef = useRef<Slider>(null)
  const SLIDER_SETTINGS = useMemo(
    () => ({
      dots: false,
      arrows: false,
      autoplay,
      infinite: true,
      autoplaySpeed: 10000,
      speed: 500,
      className: 'z-10',
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (cur, next) => changeActiveSlide(next),
    }),
    []
  )

  const isDark = theme === themes.dark

  const renderSliderControlSection = useCallback(
    (isArrows: boolean) => (
      <>
        <div className="relative">
          <div className="md:inline-block">
            <div className="flex justify-center items-center mt-14 md:mt-0 mb-10 md:ml-10 md:mb-7.5">
              <div className="hidden md:block transform hover:-translate-x-1 transition-transform">
                {isArrows && <Arrow next onClick={sliderRef?.current?.slickPrev} isDark={isDark} />}
              </div>
              <ul className="flex mx-5.2">
                {slides &&
                  slides.map((item, index) => (
                    <li
                      key={item.author}
                      className={`hover:scale-125 hover:opacity-100 transform transition w-2.5 h-2.5 rounded-full m-1.5 bg-body-bg1-dm dark:bg-body-bg3-dm cursor-pointer ${
                        index === activeSlide ? 'opacity-100' : 'opacity-20'
                      }`}
                      onClick={() => sliderRef?.current?.slickGoTo(index)}
                    />
                  ))}
              </ul>
              <div className="hidden md:block transform hover:translate-x-1 transition-transform">
                {isArrows && <Arrow onClick={sliderRef?.current?.slickNext} isDark={isDark} />}
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    [activeSlide, heading, isDark]
  )

  return (
    <div className="py-16.5 md:px-10">
      <div className="block md:hidden">{renderSliderControlSection(true)}</div>
      <div className="max-w-1310 mx-auto md:bg-white md:dark:bg-body-bg1-dm rounded-2xl relative px-7 md:px-20 md:pt-20  md:pt-7">
        <div className="flex w-full flex-col slg:flex-row ">
          <div className={`${style.testimonialsSlider} slg:max-w-540 lg:max-w-530 w-full mb-5 slg:mb-0 md:pb-0`}>
            <Slider {...SLIDER_SETTINGS} ref={sliderRef}>
              {slides && slides.map((quote, index) => <Blockquote key={index} {...quote} />)}
            </Slider>
          </div>
          <div className=" w-full flex slg:justify-end justify-center items-end -right-0 bottom-0">
            <div className="ani ani-blur ani-scale max-w-350">
              <Image
                src={isDark ? testimonials_image_dark_mode : testimonials_image}
                alt={alternative_image_text || ''}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 hidden md:flex items-center justify-center">{renderSliderControlSection(true)}</div>
      </div>
    </div>
  )
}

export const testimonialsSliderQuery = graphql`
  fragment TestimonialsSlider on MarkdownRemarkFrontmatterBody {
    slides {
      author
      position
      quote
    }
    autoplay
    heading
    testimonials_image: image {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 350, formats: [WEBP])
      }
      extension
      publicURL
    }
    testimonials_image_dark_mode: image_dark_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 350, formats: [WEBP])
      }
      extension
      publicURL
    }
    alternative_image_text
  }
`
