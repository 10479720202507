import React, { useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'

import * as style from './styles.module.css'

interface Props {
  value?: string
  label?: string
  id?: string
  checked?: boolean
  onSelect: (value: string) => void
}

export const Topic: React.FunctionComponent<Props> = ({ id, label, value, checked, onSelect }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)

  return (
    <li
      className={` ${
        !checked ? 'bg-blue-darker dark:bg-body-bg1-dm' : 'bg-text dark:bg-gray-darker'
      } " z-10 relative flex justify-between items-center max-w-xs rounded-lg " duration-300`}
    >
      <label
        htmlFor={id}
        className={`hover:opacity-50 transition " w-full h-12 pl-2 md:pr-3 md:pl-4 cursor-pointer font-ilisarniq font-normal text-sm flex items-center max-w-125" ${
          !checked ? 'text-text dark:text-text-dm ' : ' text-white dark:text-body-dm'
        } `}
      >
        {label}
      </label>
      <input
        className={`${theme === themes.dark ? style.bgPositionDark : style.bgPositionLight} ${
          checked && (theme === themes.dark ? style.darkCheckboxAfter : style.lightCheckboxAfter)
        } "relative appearance-none h-6 w-6 focus:outline-none outline-none top-0 right-2 absolute `}
        id={id}
        data-content-before=""
        type="checkbox"
        onChange={() => value && onSelect(value)}
        checked={checked}
        name="topic"
        value={value}
      />
    </li>
  )
}
