import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { ReactSVG } from 'react-svg'

import { ContactUsForm, Location, CustomLink } from '@/components'
import { ThemeContext } from '@/context'
import { Heading, Text } from '@/uikit'

import * as style from './styles.module.css'

export const ContactUsSection: React.FC<GatsbyTypes.ContactUsSectionFragment> = (data) => {
  const {
    contact_title,
    contact_lead,
    company_name,
    company_address,
    contact_social_media,
    contact_form_header,
    contact_form_description,
    contact_image,
  } = data || {}

  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === theme.dark

  return (
    <section className="flex justify-center md:mt-20 -mb-15 relative z-10 px-7.5">
      <div className="max-w-1440 w-full mb-10 md:mb-0">
        <div className="md:mb-7.5 mt-13 md:mt-0 flex justify-center flex-col md:max-w-720 max-w-450 mx-auto">
          <div className="mb-7.5">{contact_title && <Heading>{contact_title}</Heading>}</div>
          <div className="md:max-w-370 mb-15 md:mb-0">
            <Text>{contact_lead}</Text>
          </div>
        </div>
        <div>
          <div className="md:pl-0 lg:pl-28 flex flex-col-reverse md:flex-row items-center md:items-start justify-start">
            <div className="mb-25 md:mb-0 md:mr-65 lg:mr-110 md:min-w-400 xl:min-w-540 flex justify-center md:justify-start">
              <Location
                name={company_name}
                address={company_address}
                social_media={contact_social_media}
                contact_image={contact_image}
              />
            </div>
            <div className="w-full md:hidden border-t border-gray-border">
              <ul className="flex my-15 justify-evenly w-full">
                {contact_social_media &&
                  contact_social_media.map((social_link) => (
                    <li
                      className={isDark ? style.lightModeIconFill : style.darkModeIconFill}
                      key={social_link.icon?.id}
                    >
                      <CustomLink type="external" url={social_link.link} newTab={social_link.open_in_new_tab || false}>
                        <ReactSVG src={social_link.icon?.publicURL || ''} />
                      </CustomLink>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="mb-20 md:mb-0">
              <h3 className="font-ilisarniq mb-3 text-22 text-text tracking-tightest dark:text-text-dm">
                {contact_form_header}
              </h3>
              <Text className="mb-5">{contact_form_description}</Text>
              {data && <ContactUsForm {...data} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const contactUsSectionQuery = graphql`
  fragment ContactUsSection on MarkdownRemarkFrontmatterBody {
    topic_checkbox_fields {
      value
      label
    }
    textarea_placeholder
    checkbox_text
    checkbox_text_2
    input_placeholder
    button_text
    contact_title
    contact_lead
    company_name
    company_address
    contact_form_header
    contact_form_description
    contact_image {
      childImageSharp {
        gatsbyImageData(width: 570, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    contact_social_media {
      alternative_text
      name
      icon {
        id
        extension
        publicURL
      }
      link
      open_in_new_tab
    }
  }
`
