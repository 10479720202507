import React, { useState, useRef } from 'react'

import { Tooltip } from '@/components'
import { Image } from '@/uikit'

import * as styles from './styles.module.css'

type Props = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBodyEmployees,
  'alternative_avatar_text' | 'name' | 'position' | 'description'
> & {
  readonly avatar: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.File, 'publicURL' | 'extension'> & {
      readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
    }
  >
}

export const EmployeeCard: React.FC<Props> = ({ avatar, alternative_avatar_text, name, position, description }) => {
  const [visibility, changeVisibility] = useState(false)
  const changeVisibilityHandler = () => changeVisibility(!visibility)
  const employeeRef = useRef(null)

  return (
    <li ref={employeeRef} className="hover:z-20 w-1/2 md:w-1/3 lg:w-1/4  mb-12.5 md:mb-25">
      <div
        onFocus={changeVisibilityHandler}
        onBlur={changeVisibilityHandler}
        onMouseOver={changeVisibilityHandler}
        onMouseOut={changeVisibilityHandler}
        className="ani-child ani-up flex justify-center items-center flex-col max-w-125 mx-auto"
      >
        <div
          className={` ${styles.employeeBackground} mb-7.5 h-25  w-full  flex justify-center items-end rounded-semiXl `}
        >
          <Image src={avatar} alt={alternative_avatar_text || ''} />
        </div>
        <p className="mb-2.5 dark:text-text-dm text-center font-inter font-semibold leading-medium text-text text-lg">
          {name}
        </p>
        <p className="text-center dark:text-text-50-dm font-inter font-light text-black-lighter leading-small">
          {position}
        </p>
        <Tooltip parent={employeeRef} isVisible={visibility} name={name} role={position} description={description} />
      </div>
    </li>
  )
}
