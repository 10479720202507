import Markdown, { MarkdownToJSX } from 'markdown-to-jsx'
import React, { FunctionComponent } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import icon from './images/icon.png'
import * as styles from './styles.module.css'

type ChildrenComponentsProps = {
  children: React.ReactNode
}

const CodeComponent: React.FC<ChildrenComponentsProps> = ({ children }) => {
  const style = { ...vs2015 }
  style.hljs.background = null
  return (
    <div className={`${styles.highlight}`}>
      <SyntaxHighlighter language="javascript" style={style} className="dark:bg-white-bg-10 bg-body-bg1-dm max-w-full">
        {children.props && children.props.children ? children.props.children : ''}
      </SyntaxHighlighter>
    </div>
  )
}

export const H1Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h1 className="font-ilisarniq mt-10 md:mt-20 md:mb-14 mb-5 text-32 dark:text-text-dm print:text-3xl">{children}</h1>
  )
}

export const H2Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h2 className="font-bold text-3.5xl tracking-tightest leading-big md:leading-big font-ilisarniq font-bold md:text-5xl dark:text-gray-darker text-text dark:text-text-dm md:mt-18 mt-8 md:mb-7 mb-5 print:text-2xl">
      {children}
    </h2>
  )
}

export const H3Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h3 className="font-ilisarniq md:mt-15 mt-7 md:mb-7 mb-5 text-22 text-text tracking-tightest dark:text-text-dm">
      {children}
    </h3>
  )
}

export const H4Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h4 className="first:mt-0 font-ilisarniq md:mt-15 mt-7 md:mb-7 mb-5 text-text tracking-tightest dark:text-text-dm italic">
      {children}
    </h4>
  )
}

export const H5Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h5 className="font-ilisarniq md:mt-15 mt-7 md:mb-7 mb-5 text-text tracking-tightest dark:text-text-dm">
      {children}
    </h5>
  )
}

export const H6Component: FunctionComponent<Props> = ({ children }) => {
  return (
    <h6 className="font-ilisarniq md:mt-15 mt-7 md:mb-7 mb-5 text-blue-dark dark:text-orange tracking-tightest text-xl font-semibold">
      {children}
    </h6>
  )
}

export const PComponent: FunctionComponent<Props> = ({ children }) => {
  return (
    <p className="mb-5 mt-7 leading-150 tracking-wide font-light text-lg text-black-lighter text-opacity-70 dark:text-opacity-70 dark:text-text-dm print:text-sm">
      {children}
    </p>
  )
}

export const EmComponent: React.FC<ChildrenComponentsProps> = ({ children }) => (
  <em className="font-inter font-light leading-normal text-lg my-7.5 md:px-0 dark:text-text-70-dm print:text-sm">
    {children}
  </em>
)

export const StrongComponent: React.FC<ChildrenComponentsProps> = ({ children, className }) => (
  <strong
    className={`${className} font-inter font-bold text-black-lighter dark:text-text-dm leading-normal text-lg my-7.5 md:px-0 dark:text-text-80-dm print:text-sm`}
  >
    {children}
  </strong>
)

export const BlockquoteComponent: React.FC<ChildrenComponentsProps> = ({ children }) => (
  <blockquote className={`${styles.blockquote} italic my-15`}>
    <img src={icon} alt="icon" className="relative md:-bottom-6" />
    <div className="ml-5 sm:ml-15">{children}</div>
  </blockquote>
)

export const UlComponent: React.FC<Props> = ({ children }) => <ul className={`${styles.listStyle} my-5`}>{children}</ul>

export const OlComponent: React.FC<Props> = ({ children }) => (
  <ol className={`${styles.numberedListStyle} my-5`}>{children}</ol>
)

export const LiComponent: React.FC<Props> = ({ children }) => (
  <li className="px-0 relative leading-snug ml-7 mb-3 font-inter font-xl text-lg leading-semiBig text-black-lighter text-opacity-70 dark:text-opacity-70 dark:text-text-dm print:text-sm">
    {children}
  </li>
)

export const HrComponent: React.FC<Props> = ({ children }) => <hr className="my-10 block" />

export const TableComponent: React.FC<Props> = ({ children }) => (
  <table className="border-collapse border border-gray dark:border-gray-dm-70 rounded-semiXl">{children}</table>
)

export const TableHeaderComponent: React.FC<Props> = ({ children }) => (
  <th className="p-2 border border-gray dark:border-gray-dm-70">{children}</th>
)

export const TableDataComponent: React.FC<Props> = ({ children }) => (
  <td className="p-2 border border-gray dark:border-gray-dm-70">{children}</td>
)

interface Props extends MarkdownToJSX.Options {
  children: string
  externalComponents?: MarkdownToJSX.Overrides
  className?: string
}

export const MarkdownComponent: FunctionComponent<Props> = ({ children, wrapper, externalComponents, className }) => {
  // replace four spaces plus line break with just a line break (otherwise breaks code blocks)
  const parsedChildren = children.replace(/^( {4}\n)+/gm, '\n')
  return (
    <Markdown
      className={className}
      options={{
        wrapper,
        forceBlock: true,
        overrides: {
          pre: CodeComponent,
          h1: H1Component,
          h2: H2Component,
          h3: H3Component,
          h4: H4Component,
          h5: H5Component,
          h6: H6Component,
          p: PComponent,
          em: EmComponent,
          strong: StrongComponent,
          blockquote: BlockquoteComponent,
          ol: OlComponent,
          ul: UlComponent,
          li: LiComponent,
          hr: HrComponent,
          table: TableComponent,
          th: TableHeaderComponent,
          td: TableDataComponent,
          ...externalComponents,
        },
      }}
    >
      {parsedChildren}
    </Markdown>
  )
}
