import { graphql } from 'gatsby'
import React, { useCallback, useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Image, Content } from '@/uikit'

type Item = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBodyArticles,
  'template' | 'title' | 'description' | 'alternative_image_text'
> & {
  image: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.File, 'publicURL' | 'extension'> & {
      childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
    }
  >
}

export const ArticleBoxesSection: React.FC<GatsbyTypes.ArticleBoxesSectionFragment> = ({ articles }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === themes.dark

  const renderArticleBox = useCallback(
    ({ image, alternative_image_text, title, description }: Item) => (
      <li key={title} className="w-full max-w-370 flex justify-center items-center flex-col ">
        <div className="rounded-2xl bg-bottom bg-95 bg-no-repeat w-full mb-2.5 relative ">
          <div className="z-10 relative ">
            <Image
              src={image}
              alt={alternative_image_text || ''}
              className="ani-child ani-scale ani-blur relative -bottom-1"
            />
          </div>
          <div className="bg-noise w-full rounded-2xl max-h-9/10 h-full bottom-0 z-0 absolute bg-gray-darker dark:bg-body-bg2-dm" />
        </div>
        <div className="p-10 bg-white dark:bg-body-bg1-dm rounded-2xl flex-1">
          <p className="font-inter font-semibold text-lg text-text dark:text-text-dm leading-medium mb-2">{title}</p>
          <p className="font-inter font-light text-lg text-text-70 dark:text-text-70-dm leading-semiBig ">
            {description}
          </p>
        </div>
      </li>
    ),
    [isDark]
  )

  return (
    <div className="py-16.5">
      <Content>
        <ul
          data-ani-stagger="100"
          className="ani-group w-full flex-wrap mx-auto flex gap-x-10 justify-center gap-y-20 md:gap-y-27 "
        >
          {articles && articles.map((articleItem) => articleItem && renderArticleBox(articleItem))}
        </ul>
      </Content>
    </div>
  )
}

export const articleSectionBoxesQuery = graphql`
  fragment ArticleBoxesSection on MarkdownRemarkFrontmatterBody {
    articles {
      template
      title
      description
      alternative_image_text
      image {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 370, formats: [WEBP])
        }
      }
    }
  }
`
