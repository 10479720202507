import { graphql } from 'gatsby'
import React from 'react'

// import * as styles from './styles.module.css'

export const Statistics: React.FC<GatsbyTypes.StatisticsFragment> = ({ statistics }) => {
  return (
    <div className="px-7.5">
      <div className="max-w-540 mx-auto md:max-w-770 grid md:grid-cols-3 mb-24 gap-5">
        {statistics?.map((stat, i) => {
          return (
            <div key={i}>
              <h3 className="ani ani-left text-blue-dark dark:text-orange whitespace-nowrap">
                <span className="inline-block text-7xl font-extrabold">{stat?.number}</span>
                <span className="ani ani-left inline-block text-2xl font-extrabold font-ilisarniq ml-2">
                  {stat?.title}
                </span>
              </h3>
              {stat?.description && (
                <p className="dark:text-text-dm text-lg mt-1 max-w-200 leading-snug">{stat?.description}</p>
              )}
              {stat?.subtitle && (
                <p className="dark:text-text-dm text-lg mt-5">
                  <strong>{stat?.subtitle}</strong>
                </p>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const StatisticsQuery = graphql`
  fragment Statistics on MarkdownRemarkFrontmatterBody {
    statistics {
      number
      title
      subtitle
      description
    }
  }
`
