import { graphql, useStaticQuery } from 'gatsby'
import React, { useState, useMemo } from 'react'

import { FilterControllers } from '@/components'
import { SORTING_DROPDOWN_DATA, MONTH_ORDER, SORTING_OPTIONS } from '@/consts'
import { capitalizeFirstLetter, getCurrentMonthAndYear } from '@/helpers/utils'
import { ButtonPrimmary, Label } from '@/uikit'

import { Post } from './components/post'
import { getGroupedAndSlicedPosts } from './helpers'

type Option = {
  label: string
  value: string
}

function createAllCategoryOptions(array?: ReadonlyArray<string>): Option[] {
  if (!array) return []
  return [{ label: 'All', value: '' }].concat(
    array.map((category) => ({ label: capitalizeFirstLetter(category), value: category }))
  )
}

const LIST_OF_POSTS_QUERY = graphql`
  query ListOfAllPosts {
    list: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/.*/" } }
      sort: { fields: frontmatter___date_of_publication, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            url
            title
            author
            date_of_publication
            post_categories
            bp_image_light {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.77, formats: [WEBP])
              }
              extension
              publicURL
            }
            bp_image_dark {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.77, formats: [WEBP])
              }
              extension
              publicURL
            }
            excerpt
          }
        }
      }
    }
    categories: markdownRemark(fileAbsolutePath: { regex: "/content/data/post-categories.md/" }) {
      frontmatter {
        list_of_categories
      }
    }
  }
`

export const ListOfPosts: React.FC<GatsbyTypes.ListOfPostsFragment> = ({
  label,
  heading,
  initial_amount,
  button_label,
}) => {
  const data = useStaticQuery<GatsbyTypes.ListOfAllPostsQuery>(LIST_OF_POSTS_QUERY)
  const [sortKey, setSortingKey] = useState(SORTING_OPTIONS.date)
  const [paginationStep, setPaginationStep] = useState(1)
  const [activeFilter, setActiveFilter] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const { list, categories } = data || {}
  const listOfCategories = categories.frontmatter.list_of_categories
    ? createAllCategoryOptions(categories.frontmatter.list_of_categories)
    : []

  const setSorting = (value: string) => {
    setSortingKey(value)
  }

  const handleShowMore = () => {
    setPaginationStep(paginationStep + 1)
  }

  const isSortingByDate = sortKey === SORTING_OPTIONS.date
  const VISIBLE_ELEMENTS = paginationStep * initial_amount

  const posts = useMemo(() => {
    let processedPosts = list.edges
    if (!isSortingByDate) {
      processedPosts = [...list.edges].sort(function (a, b) {
        const nameA = a.node.frontmatter.title.toUpperCase()
        const nameB = b.node.frontmatter.title.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
    if (activeFilter) {
      processedPosts = processedPosts.filter(({ node }) => node.frontmatter.post_categories.includes(activeFilter))
    }
    if (searchValue) {
      processedPosts = processedPosts.filter(({ node }) =>
        node.frontmatter.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    }
    return processedPosts
  }, [activeFilter, isSortingByDate, searchValue])

  const groupedAndSlicedPosts = useMemo(() => {
    return getGroupedAndSlicedPosts(posts, isSortingByDate, VISIBLE_ELEMENTS)
  }, [posts, isSortingByDate, VISIBLE_ELEMENTS])

  const collectionKeys = useMemo(() => {
    return isSortingByDate
      ? Object.keys(groupedAndSlicedPosts).sort((a, b) =>
          MONTH_ORDER.findIndex((month) => month === a) > MONTH_ORDER.findIndex((month) => month === b) ? -1 : 1
        )
      : Object.keys(groupedAndSlicedPosts).sort()
  }, [isSortingByDate, activeFilter, searchValue])

  return (
    <section className="blog-like pt-16 pb-16 lg:pb-30">
      <div className="max-w-full">
        <div className="max-w-540 mx-auto px-7.5 sm:px-0 lg:px-7.5 lg:max-w-1060 ">
          <div className="mb-10.5">
            <Label>{label}</Label>
            <h2 className="my-4 text-text text-40 dark:text-white font-ilisarniq font-bold leading-big tracking-tightest lg:text-5xl">
              {heading}
            </h2>
          </div>
          <div className="mb-10.5 md:mb-18">
            <FilterControllers
              sort={false}
              search
              activeKey={activeFilter}
              options={listOfCategories}
              onSelect={setActiveFilter}
              onSortChange={setSorting}
              onSearchChange={onSearchChange}
              searchValue={searchValue}
              sortOptions={SORTING_DROPDOWN_DATA}
            />
          </div>
          {collectionKeys
            .filter((keyName) => !!groupedAndSlicedPosts[keyName] && groupedAndSlicedPosts[keyName].length > 0)
            .map((keyName) => (
              <div key={keyName}>
                <div className="font-semibold font-inter mb-10.5 md:mb-22">
                  <h3 className="leading-medium text-lg text-text-80 dark:text-white">{keyName}</h3>
                </div>
                <div className="md:flex md:gap-x-3 lg:gap-x-9 xl:gap-x-13 flex-wrap">
                  {groupedAndSlicedPosts[keyName]?.map(({ node: { frontmatter, id } }) => (
                    <Post key={id} {...frontmatter} />
                  ))}
                </div>
              </div>
            ))}
          {VISIBLE_ELEMENTS < posts.length && (
            <div className="flex flex-co items-center justify-center">
              <span>
                <ButtonPrimmary onClick={handleShowMore}>{button_label}</ButtonPrimmary>
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const listOfPostsQuery = graphql`
  fragment ListOfPosts on MarkdownRemarkFrontmatterBody {
    label
    heading
    initial_amount
    button_label
  }
`
