export const SORTING_OPTIONS = {
  date: 'date',
  alphabetically: 'alphabetically',
}

export const SORTING_DROPDOWN_DATA = [
  {
    value: 'date',
    label: 'Sort by Latest',
  },
  {
    value: 'alphabetically',
    label: 'Sort by Alphabet',
  },
]

export const MONTH_ORDER: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

enum THEMES {
  light = 'light',
  dark = 'dark',
}

export const themes = {
  light: THEMES.light,
  dark: THEMES.dark,
}

export const LOCALSTORAGE_THEME_KEY = 'theme'
