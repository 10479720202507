import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { signUpToNewsletter } from '@/helpers'
import { Subheading, FormField, Checkbox, ButtonPrimmary, Content } from '@/uikit'

import useNewsletterForm from './useNewsletterForm'

export const NewsletterForm: React.FC<GatsbyTypes.NewsletterFormFragment> = ({
  header,
  button_text = '',
  checkbox_text = '',
  input_placeholder,
  lead,
}) => {
  const {
    formState,
    onInputChange,
    onCheckboxChange,
    onAnalyticsInit,
    validate,
    resetErrors,
    displayError,
    changeSubmittingStatus,
  } = useNewsletterForm()

  const { email, checked, submitting, errors, hutk, pageUri, pageName } = formState

  async function sendForm() {
    changeSubmittingStatus()
    signUpToNewsletter({
      email,
      checkbox: formState.checked,
      hutk,
      pageUri,
      pageName,
    })
      .then((data) => {
        const response = JSON.parse(data)
        if (response?.inlineMessage) {
          navigate('/newsletter-signup-sent')
        } else {
          displayError('submit', `Error: ${response?.message}`)
          console.error(response?.message, response?.errors)
        }
        changeSubmittingStatus()
      })
      .catch((data) => {
        displayError('submit', 'There was an error submitting the form')
        changeSubmittingStatus()
      })
  }

  const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    resetErrors()
    const isValid = validate()

    if (isValid) {
      sendForm()
    }
  }

  useEffect(() => {
    onAnalyticsInit('hutk', document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'))
    onAnalyticsInit('pageUri', document.location.href)
    onAnalyticsInit('pageName', document.location.pathname)
  }, [])

  return (
    <div className="pt-10 pb-15">
      <Content>
        <div className="flex justify-center items-center flex-col pt-20 px-7.5 pb-25 sm:py-33 rounded-2xl bg-white dark:bg-body-bg1-dm">
          <div className="max-w-740">
            <div className="flex justify-center items-start sm:items-center flex-col">
              <div className="mb-7.5 text-left sm:text-center max-w-280 sm:max-w-full">
                {header && <Subheading bold>{header}</Subheading>}
              </div>
              <div className="w-full sm:max-w-740 text-left sm:text-center max-w-270  sm:mb-23 mb-15 text-black-lighter">
                <p className="font-inter font-light text-lg text-black-lighter dark:text-text-70-dm">{lead}</p>
              </div>
              <form className=" max-w-450" onSubmit={onSubmit}>
                <div className="flex justify-between mb-8">
                  <div className="ani ani-mask-right w-full sm:max-w-290">
                    <FormField
                      placeholder={input_placeholder}
                      onChange={onInputChange}
                      value={email}
                      id="contact-form-email"
                      error={errors.email}
                    />
                  </div>
                  <div className="w-auto min-w-130 sm:block hidden">
                    <ButtonPrimmary type="submit" disabled={submitting} hasArrow={false}>
                      {button_text}
                    </ButtonPrimmary>
                  </div>
                </div>
                <div className="max-w-450">
                  <Checkbox
                    id="contact-form-newsletter_checkbox"
                    onChange={onCheckboxChange}
                    value={checked}
                    error={errors.checkbox}
                    name="newsletter_checkbox"
                  >
                    {checkbox_text}
                  </Checkbox>
                </div>
                <div className="block w-full sm:hidden mt-7.5">
                  <ButtonPrimmary type="submit" disabled={submitting}>
                    {button_text}
                  </ButtonPrimmary>
                </div>
                {errors.submit && (
                  <p className="pl-4 mt-4 font-inter font-normal text-red text-xs text-opacity-50">{errors.submit}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </Content>
    </div>
  )
}

export const NewsletterFormQuery = graphql`
  fragment NewsletterForm on MarkdownRemarkFrontmatterBody {
    header
    lead
    button_text
    checkbox_text
    input_placeholder
  }
`
