import React from 'react'

import { CustomLink } from '@/components'
import { ArrowUpIcon } from '@/icons'

import * as styles from './styles.module.css'

export const BackToTop: React.FC = () => {
  return (
    <>
      <div
        id="top"
        data-ani-reset
        className={`${styles.backToTopAnchor} ani ani-no-fade absolute top-0 right-0 w-0 h-screen pointer-events-none is-shown`}
        aria-hidden="true"
      />
      <CustomLink
        title="Back to Top"
        type="internal"
        url="#top"
        className={`${styles.backToTop} z-30 group transform hover:scale-110 duration-500 bg-blue-lighter dark:bg-body-bg-dm-static hover:bg-orange dark:hover:bg-orange hover:text-white shadow-md flex items-center justify-center w-12 h-12 rounded-full fixed right-3 bottom-3 print:hidden`}
      >
        <span className="flex" aria-label="Back to Top">
          <ArrowUpIcon className="block group-hover:scale-125 transform transition-transform" />
        </span>
      </CustomLink>
    </>
  )
}
