import React, { useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'

export const LinkedinIconComponent = () => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === themes.dark
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.9968 15.9995V15.9988H16.0008V10.1308C16.0008 7.26016 15.3828 5.04883 12.0268 5.04883C10.4135 5.04883 9.33082 5.93416 8.88882 6.77349H8.84216V5.31683H5.66016V15.9988H8.97349V10.7095C8.97349 9.31683 9.23749 7.97016 10.9622 7.97016C12.6615 7.97016 12.6868 9.55949 12.6868 10.7988V15.9995H15.9968Z"
          className={` ${isDark ? 'text-white-clear' : 'text-text text-opacity-80'} "fill-current"`}
        />
        <path
          d="M0.264648 5.31738H3.58198V15.9994H0.264648V5.31738Z"
          className={` ${isDark ? 'text-white-clear' : 'text-text text-opacity-80'} "fill-current"`}
        />
        <path
          d="M1.92133 0C0.860667 0 0 0.860667 0 1.92133C0 2.982 0.860667 3.86067 1.92133 3.86067C2.982 3.86067 3.84267 2.982 3.84267 1.92133C3.842 0.860667 2.98133 0 1.92133 0V0Z"
          className={` ${isDark ? 'text-white-clear' : 'text-text text-opacity-80'} "fill-current"`}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
