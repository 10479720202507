import { THEMES, ACTIONS } from './types'

export const toggleTheme: () => { type: ACTIONS.toggle } = () => ({
  type: ACTIONS.toggle,
})

export const setTheme = (theme: THEMES) => ({
  type: ACTIONS.setTheme,
  payload: theme,
})
