import React from 'react'

import * as style from './styles.module.css'

export type Quote = {
  quote?: string
  author?: string
  position?: string
}

export const Blockquote: React.FC<Quote> = ({ quote, author, position }) => {
  if (!quote) return null

  return (
    <blockquote className={style.blockquote}>
      <p className="font-ilisarniq text-3xl font-normal text-text dark:text-text-70-dm leading-9 tracking-tightest">
        {quote}
      </p>
      <footer className="mt-8">
        {author && (
          <p className="font-ilisarniq tracking-tightest leading-semiBig font-normal text-22 text-gray-slider dark:text-gray-darker">
            {author}
          </p>
        )}
        {position && (
          <p className="mt-2.5 font-ilisarniq font-bold text-xl tracking-tightest text-text dark:text-text-50-dm leading-bigger">
            {position}
          </p>
        )}
      </footer>
    </blockquote>
  )
}
