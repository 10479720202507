import React, { useState, useEffect } from 'react'

interface TooltipProps {
  parent: React.RefObject<HTMLLIElement>
  isVisible: boolean
  name?: string
  role?: string
  description?: string
}

const TOOLTIP_MAX_WIDTH = 320
const TOOLTIP_MAX_WIDTH_MOBILE = 180
const TABLET_VIEWPORT = 768
const MOBILE_VIEWPORT = 413
const MAX_MOBILE_OFFSET = 100

export const Tooltip: React.FC<TooltipProps> = ({ parent, isVisible, name, role, description }) => {
  const [inverted, changePosition] = useState(false)

  useEffect(() => {
    if (!isVisible) return

    if (
      window.innerWidth > TABLET_VIEWPORT &&
      parent.current &&
      parent.current.clientWidth + parent.current.offsetLeft + TOOLTIP_MAX_WIDTH > window.innerWidth
    ) {
      changePosition(true)
      return
    }

    if (
      window.innerWidth > MOBILE_VIEWPORT &&
      window.innerWidth < TABLET_VIEWPORT &&
      parent.current &&
      parent.current.clientWidth + parent.current.offsetLeft + TOOLTIP_MAX_WIDTH_MOBILE > window.innerWidth
    ) {
      changePosition(true)
      return
    }

    if (parent.current && parent.current.offsetLeft > MAX_MOBILE_OFFSET) {
      changePosition(true)
    }
  }, [isVisible])

  return (
    <div
      className={`${isVisible ? ' opacity-1 ' : ' opacity-0 pointer-events-none '} ${
        inverted ? ' -translate-x-full sm:left-1/3 ' : ' translate-x-0 sm:left-2/3 '
      }
                " flex transition-opacity shadow-strong -translate-y-6 z-20 w-230 h-auto sm:w-72 absolute left-1/2 flex-col top-1/2 left:1/2 transform my-0 mx-auto text-white bg-body-bg1-dm rounded-xl "`}
    >
      <div className="flex flex-col justify-center items-center relative px-2.5 sm:px-2.5 md:pr-3 pt-7 pb-12 w-full">
        <p className="text-gray-darker font-inter font-semibold text-lg">{name}</p>
        <p className=" text-center font-inter font-extralight text-base text-gray-darker text-opacity-70">{role}</p>
        <p className="text-gray-darker w-full max-w-6xl font-inter font-extralight text-center pt-5 -ml-1 leading-bigger">
          {description}
        </p>
      </div>
    </div>
  )
}
