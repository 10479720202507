import { graphql } from 'gatsby'
import React from 'react'

import { MarkdownComponent } from '@/components'

export const MarkdownWYSIWYG: React.FC<GatsbyTypes.MarkdownWYSIWYGFragment> = ({ markdown_wysiwyg }) => {
  return (
    <div className="px-7.5">
      <div className="max-w-540 md:max-w-770 mx-auto leading-snug tracking-wide">
        <MarkdownComponent>{markdown_wysiwyg}</MarkdownComponent>
      </div>
    </div>
  )
}

export const MarkdownWYSIWYGQuery = graphql`
  fragment MarkdownWYSIWYG on MarkdownRemarkFrontmatterBody {
    markdown_wysiwyg
  }
`
