import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import { formatDate, getAllMonthNames, isSameMonth } from '@/helpers/utils'
import { Image } from '@/uikit'

type Frontmatter = Pick<GatsbyTypes.MarkdownRemarkFrontmatter, 'date_of_publication' | 'title' | 'url' | 'excerpt'> & {
  readonly cs_image_light: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
  readonly cs_image_dark: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
}

type CaseStudy = {
  readonly node: Pick<GatsbyTypes.MarkdownRemark, 'id'> & {
    readonly frontmatter: Frontmatter
  }
}

export const ListOfCaseStudies: FunctionComponent<GatsbyTypes.ListOfCaseStudiesFragment> = ({
  heading,
  initial_amount,
  button_label,
}) => {
  const data = useStaticQuery<GatsbyTypes.ListOfAllCaseStudiesQuery>(graphql`
    query ListOfAllCaseStudies {
      list: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/case-studies/.*/" }
          frontmatter: { url: { ne: "safety-page" } }
        }
        sort: { fields: frontmatter___date_of_publication, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              date_of_publication
              title
              url
              excerpt
              cs_image_light {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 540, formats: [WEBP])
                }
                extension
                publicURL
              }
              cs_image_dark {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 540, formats: [WEBP])
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const { list } = data || {}
  const paginationStep = useRef(1)
  const [orderedList, setOrderedList] = useState<CaseStudy[]>([])
  const [monthsList, setMonthsList] = useState<string[]>([])

  function setResultsList(listWithAllCaseStudies: CaseStudy[]) {
    const listOfMonths = getAllMonthNames<Frontmatter>(
      listWithAllCaseStudies.map(({ node }) => node.frontmatter),
      'date_of_publication'
    )
    setOrderedList(listWithAllCaseStudies)
    setMonthsList(listOfMonths)
  }

  function handleShowMore() {
    const newCounter = paginationStep.current + 1
    const paginatedList = list.edges.slice(0, newCounter * initial_amount)
    setResultsList(paginatedList)
    paginationStep.current = newCounter
  }

  useEffect(() => {
    if (list.edges?.length) {
      const paginatedList = list.edges.slice(0, initial_amount)
      const listOfMonths = getAllMonthNames<Frontmatter>(
        paginatedList.map(({ node }) => node.frontmatter),
        'date_of_publication'
      )
      setOrderedList(paginatedList)
      setMonthsList(listOfMonths)
    }
  }, [])

  return (
    <section className="pt-16 pb-16 lg:pt-110 lg:pb-30">
      <div className="max-w-full">
        <div className="max-w-540 mx-auto px-7.5 sm:px-0 lg:px-7.5 lg:max-w-1060 ">
          <div className="mb-28 lg:mb-20">
            <h2 className="text-text text-40 dark:text-white font-ilisarniq font-bold leading-big tracking-tightest lg:text-5xl">
              {heading}
            </h2>
          </div>
          <div className="mb-15.5 lg:mb-22">
            {monthsList.map((month) => {
              return (
                <div key={month}>
                  <div>
                    {orderedList
                      .filter(({ node }) => isSameMonth(node.frontmatter.date_of_publication, month))
                      .map(({ node: { id, frontmatter } }) => {
                        return (
                          <Link
                            to={`/case-studies/${frontmatter.url}`}
                            className="mb-15.5 block lg:mb-22 lg:flex lg:items-center lg:gap-x-110 group"
                            key={id}
                          >
                            <div className="mb-17.5 lg:mb-0 lg:w-2/5">
                              {frontmatter.cs_image_light && (
                                <div className="min-h-230 dark:hidden max-w-full flex items-stretch justify-center">
                                  <Image
                                    src={frontmatter.cs_image_light}
                                    alt="post image"
                                    className="transition-transform duration-500 hover:scale-105 transform"
                                  />
                                </div>
                              )}
                              {frontmatter.cs_image_dark && (
                                <div className="min-h-230 dark:flex hidden max-w-full items-stretch justify-center">
                                  <Image
                                    src={frontmatter.cs_image_dark}
                                    alt="post image"
                                    className="transition-transform duration-500 hover:scale-105 transform"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="lg:w-3/5">
                              <div className="font-semibold my-4">
                                <h4 className="leading-34 tracking-tightest text-28 text-text dark:text-white font-ilisarniq lg:text-32 lg:leading-38 lg:font-normal dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                                  {frontmatter.title}
                                </h4>
                              </div>
                              <div className="font-inter font-light dark:text-white leading-semiBig text-lg opacity-70 text-black-lighter">
                                {frontmatter.excerpt}
                              </div>
                            </div>
                          </Link>
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
          {paginationStep.current * initial_amount < list.edges.length && (
            <div className="flex flex-co items-center justify-center">
              <span>
                <ButtonPrimmary onClick={handleShowMore}>{button_label}</ButtonPrimmary>
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const listOfCaseStudies = graphql`
  fragment ListOfCaseStudies on MarkdownRemarkFrontmatterBody {
    heading
    initial_amount
    button_label
  }
`
