import React from 'react'

type IProps = {
  next?: boolean
  onClick?: () => void
  isDark?: boolean
}

// @TODO: Fix arrows (export it properly)
const Arrow: React.FC<IProps> = ({ next, onClick, isDark }) => (
  <svg
    onClick={onClick}
    fill="#000"
    height="25"
    viewBox="0 0 29 25"
    width="29"
    xmlns="http://www.w3.org/2000/svg"
    className={`cursor-pointer ${next && 'transform rotate-180'}`}
  >
    <g stroke="#2b2340" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
      <path
        d="m16.5833 22.8333 10.4167-10.4166-10.4167-10.4167"
        stroke={isDark ? '#FBFAFD' : '#2B2340'}
        className="bg-transparent text-transparent"
      />
      <path
        d="m1.99935 22.8333 10.41665-10.4166-10.41665-10.4167"
        stroke={isDark ? '#FBFAFD' : '#2B2340'}
        className="bg-transparent text-transparent"
      />
    </g>
  </svg>
)

export default Arrow
