import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useContext } from 'react'

import { ThemeToggler, CustomLink } from '@/components'
import { themes } from '@/consts'
import { ThemeContext, LayoutContext, toggleLayout } from '@/context'
import { Image } from '@/uikit'

import * as styles from './styles.module.css'

export const MobileMenu: React.FC<GatsbyTypes.HeaderFragment> = ({
  links,
  logo,
  logo_dark_mode,
  accent_mobile,
  accent_mobile_dark_mode,
  alternative_logo_text,
  alternative_accent_text,
}) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const { state, dispatch } = useContext(LayoutContext)

  const {
    allFile: { nodes: lineImages },
  } = useStaticQuery<GatsbyTypes.NavLineImagesQueryQuery>(
    graphql`
      query NavLineImagesQuery {
        allFile(filter: { name: { regex: "/^nav-line-3|nav-line-2|nav-line-1$/" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 280, formats: [WEBP])
            }
            extension
            publicURL
          }
        }
      }
    `
  )

  return (
    <nav
      className={`${
        state.mobileMenu ? ' h-screen ' : ' h-20 '
      } " absolute flex md:hidden z-50 pt-6.2 pl-1.5 pr-5 top-0 w-full flex-col dark:bg-body-bg1-dm bg-white overflow-hidden "`}
    >
      <div className="flex justify-between h-auto z-10 pl-3.5">
        <Link to="/">
          <Image
            src={theme === themes.dark ? logo_dark_mode : logo}
            alt={alternative_logo_text || ''}
            width="153"
            height="40"
          />
        </Link>
        <button
          onClick={() => dispatch(toggleLayout())}
          className={`${styles.hamburger} ${state.mobileMenu ? 'active' : ''} focus:outline-none text-orange`}
          type="button"
          aria-label="Open Main Menu"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>
      <div
        data-ani-stagger="50"
        className={`${state.mobileMenu ? '' : 'hidden'} ani-group overflow-y-auto z-10 relative`}
      >
        <ul className="flex flex-col pt-16 z-10 pl-5 ">
          {links &&
            links.map((link, i) => (
              <li className="my-4.5 ani-child ani-blur" key={i} style={{ '--ani-duration': '0.5s' }}>
                <CustomLink
                  url={`/${link.url}`}
                  type={link.type}
                  newTab={link.new_tab || false}
                  activeClassName="inline-block border-b-2 border-accent pb-1"
                >
                  <p className=" font-ilisarniq font-normal text-2xl text-text tracking-wide dark:text-gray-darker">
                    {link?.title}
                  </p>
                </CustomLink>
              </li>
            ))}
        </ul>
        <div className="ani-child flex justify-start items-center z-10 mt-12 pl-6 mb-28">
          <ThemeToggler id="toggle-mobile-menu" />
        </div>
      </div>
      <div
        data-ani-stagger="200"
        data-ani-reset
        className={`${
          state.mobileMenu ? '' : 'hidden'
        } ani-group absolute z-0 -bottom-7 right-0 z-10 pointer-events-none`}
      >
        <Image
          src={theme === themes.dark ? accent_mobile_dark_mode : accent_mobile}
          alt={alternative_accent_text || ''}
          className="ani ani-blur"
          data-ani-reset
        />
        {lineImages.length &&
          lineImages.reverse().map((image: object, i: number) => {
            return (
              <div className="absolute ease-in z-10 top-0 left-0 ani-child ani-mask-up" key={i}>
                <Image
                  key={i}
                  src={image}
                  alt="Line"
                  aria-hidden="true"
                  style={{ '--ani-duration': `${(i + 1) * 0.5}s` }}
                />
              </div>
            )
          })}
      </div>
    </nav>
  )
}
