import React from 'react'

export const Subheading: React.FC<{ children: string; bold?: boolean }> = ({ children, bold }) => (
  <h2
    className={` ${
      bold ? 'font-bold' : 'font-normal'
    } text-3.5xl tracking-tightest leading-big md:leading-big font-ilisarniq font-bold md:text-5xl dark:text-gray-darker dark:duration-500 duration-500`}
  >
    {children}
  </h2>
)
