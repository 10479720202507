import React, { useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'

import * as style from './styles.module.css'

interface CheckboxProps {
  id: string
  name: string
  children: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: boolean
  error?: string | null
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, children, name, onChange, value, error }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)

  return (
    <>
      <div className={` ${theme === themes.dark ? style.checkboxDark : style.checkbox}`}>
        <div>
          <input
            id={id}
            onChange={onChange}
            name={name}
            checked={value}
            type="checkbox"
            className={` align-top h-auto bg-left `}
          />
        </div>
        <label
          htmlFor={id}
          className="ml-7 font-inter font-light tracking-normal text-sm sm:text-base text-text dark:text-text-70-dm"
        >
          {children}
        </label>
      </div>
      {error && <p className="pl-4 mt-4 font-inter font-normal text-red text-xs text-opacity-50">{error}</p>}
    </>
  )
}
