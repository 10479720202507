import React from 'react'

interface FormFieldProps {
  placeholder?: string
  name?: string
  error?: string
  textarea?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  value: string
  secondaryVariant?: boolean
  className?: string
}

interface ComponentTypeProps {
  isTextarea: boolean
  props: FormFieldProps
}

const FormFieldComponentType: React.FC<ComponentTypeProps> = ({ isTextarea, ...props }) =>
  isTextarea ? <textarea {...props} /> : <input type="text" {...props} />

export const FormField: React.FC<FormFieldProps> = ({
  name,
  id,
  value,
  error,
  onChange,
  placeholder,
  textarea = false,
  secondaryVariant,
  children,
  className = '',
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <div className="relative">
        <div className="absolute top-3.5 left-3">{children}</div>
        <FormFieldComponentType
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          isTextarea={textarea}
          className={` ${error && 'bg-text-50-bg2 border-red-md dark:bg-red-base-70'} ${
            secondaryVariant ? 'bg-body-bg2' : 'bg-white-bg-70'
          }
                caret-blue-caret resize-none flex justify-start items-center w-full  font-inter font-normal	rounded-lg py-2 px-4 placeholder-text-30 border-transparent dark:broder-white-bg-15 placeholder-opacity-30 duration-200 outline-none focus:outline-none focus:border-blue-light dark:focus:border-blue border-2 focus:duration-200 focus:border-blue-light dark:bg-white-bg-weak dark:bg-opacity-30 dark:placeholder-white-clear dark:border-black-0.5 dark:text-gray-darker
                ${children && 'pl-8 '}`}
        />
      </div>
      {error && (
        <label htmlFor={id} className="pl-4 font-inter font-normal text-red text-xs text-opacity-50">
          {error}
        </label>
      )}
    </div>
  )
}
