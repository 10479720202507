import { useState, useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'
// pass a query like `(min-width: 768px)`
export function useMatchMedia(query: string): boolean {
  const isMobile = isBrowser ? matchMedia(query).matches : false
  const [matches, setMatches] = useState(isMobile)

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches)
    if (isBrowser) {
      const mediaQueryList = matchMedia(query)
      mediaQueryList.addEventListener('change', onChange)
    }

    return () => {
      const mediaQueryList = matchMedia(query)
      mediaQueryList.removeEventListener('change', onChange)
    }
  }, [query])

  return matches
}
