export enum ACTIONS {
  toggleMobileMenu = 'toggleMobileMenu',
}

export type State = {
  mobileMenu: boolean
}

export type Action = {
  type: ACTIONS.toggleMobileMenu
}
