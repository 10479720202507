import { globalHistory } from '@reach/router'
import { useEffect, useState } from 'react'

// apply basic scroll-based classes (works horizontal/vertical easier than gsap's ScrollTrigger)
export const ViewportAnimationClasses = ({ selector, threshold = [0, 0.2, 1], defaultThreshold = 0.2 }) => {
  // track current page path
  const [path, setPath] = useState('')

  useEffect(() => {
    // wait for the page transition to occur (start just a little before)
    setTimeout(() => {
      // coordinate add/remove of shown class when in viewport
      const ani = document.querySelectorAll(selector)
      // /*DEBUG*/let ani = [document.querySelector('.cta-grid__item')];ani[0].style.background='aquamarine'

      // if we have elements to animate

      if (ani) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.map((entry) => {
              // get the current percentage in screen (round as it'll always be a few decimals off)
              const cur = Math.round(entry.intersectionRatio * 10) / 10
              // get the percentage after which we want to show (default to any part shown)
              const want = entry.target.dataset.aniThreshold
                ? parseFloat(entry.target.dataset.aniThreshold)
                : defaultThreshold
              // /*DEBUG*/console.table({cur, want, ratio: entry.intersectionRatio, threshold: parseFloat(entry.target.dataset.threshold),addFully: (1 === cur),removeFully: (0 === entry.intersectionRatio),addPartial: !(1 === cur) && !(0 === entry.intersectionRatio) && (cur >= want)})

              // if we're to animate and reset once scrolled beyond
              if (entry.target.dataset.aniReset) {
                // if element is in (may be slightly sub 1)
                if (cur === 1) {
                  entry.target.classList.add('is-shown')
                  // if element is completely out
                } else if (entry.intersectionRatio === 0) {
                  entry.target.classList.remove('is-shown')
                  // if element is part way in/out
                } else {
                  // if we're scrolled to or beyond where we want to be
                  if (cur >= want) {
                    entry.target.classList.add('is-shown')
                  }
                }
                // if we should only animate once (no reset once scrolled beyond)
              } else if (entry.intersectionRatio !== 0 && (cur === 1 || cur >= want)) {
                entry.target.classList.add('is-shown')
              }
            })
          },
          { threshold }
        )

        // attach animations to our elements
        ani.forEach((el) => {
          observer.observe(el)

          // stagger animations if appropriate
          if (el.dataset.aniStagger) {
            const children = el.querySelectorAll('.ani-child')
            if (children) {
              children.forEach((child, i) => {
                child.style.setProperty('--ani-delay', `${i * el.dataset.aniStagger}ms`)
              })
            }
          }
        })
      }
    }, 150)

    // force refresh of animations when the page changes
    return globalHistory.listen(({ action, location: { pathname } }) => {
      setPath(pathname)
    })
  }, [path])

  return null
}
