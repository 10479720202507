import React, { useContext } from 'react'

import { themes } from '@/consts'
import { ThemeContext } from '@/context'

import * as style from './styles.module.css'

type optionValue = { value: string; label: string }

type SelectProps = {
  secondary?: boolean
  id: string
  name: string
  data?: optionValue[]
  value?: string
  onChange?: (value: string) => void
}

export const Select: React.FC<SelectProps> = ({ secondary, id, value, name, data, onChange }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === themes.dark
  const darkArrowStyle = isDark ? style.selectBeforeDM : style.selectBefore

  return (
    <fieldset
      className={` ${
        secondary ? `${darkArrowStyle} hover:bg-orange-lightest` : `${style.selectAfter} hover:bg-orange-base-10`
      } relative flex justify-center items-center" rounded-md duration-300 cursor-pointer`}
    >
      <select
        name={name}
        aria-label={name}
        id={id}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange && onChange(e.target.value)}
        className={`relative appearance-none z-10 outline-none bg-none bg-transparent font-ilisarniq text-base pb-2 pr-6 pl-3 pt-1 font-semibold cursor-pointer ${
          secondary ? style.selectBefore : style.selectAfter
        } ${secondary ? 'text-blue-dark dark:text-blue-light' : 'text-orange pr-3'}`}
      >
        {data?.map((position) => (
          <option key={position?.value} value={position?.value}>
            {position?.label}
          </option>
        ))}
      </select>
    </fieldset>
  )
}
