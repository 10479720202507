import React, { useRef, useState } from 'react'

const Legend = ({ children }) => {
  return <legend className="dark:text-text-dm text-lg font-bold mb-4">{children}</legend>
}

const Label = ({ children, htmlFor }) => {
  return (
    <label htmlFor={htmlFor} className="dark:text-text-dm text-lg font-bold mb-4 inline-block">
      {children}
    </label>
  )
}

const Errors = ({ error, name }) => {
  return error ? (
    <label htmlFor={name} className="absolute pl-4 pt-px font-inter font-normal text-red text-xs text-opacity-50">
      {error}
    </label>
  ) : null
}

export const FileField = ({ field, label, required, inputState, updateInputState }) => {
  const fieldState = inputState[`${field.name}_content_filename`]

  const setTextVersion = (event, showText) => {
    event.preventDefault()
    updateInputState(`${field.name}_content_filename`, showText, 'showTextVersion')
  }
  const hasCompanion = !!inputState[`${field.name}_text`]

  const [draggedOn, setDraggedOn] = useState()

  const ref = useRef()
  const removeFile = (event) => {
    event?.preventDefault()
    ref.current.value = ''
    updateInputState(`${field.name}_content`, '')
    updateInputState(`${field.name}_content_filename`, '')
  }

  return (
    <fieldset className="relative mb-6">
      <div>
        <Label htmlFor={field.name}>{`${label}${required ? '*' : ''}`}</Label>
        {hasCompanion && !fieldState.value && (
          <em className="text-text-35 dark:text-text-35-dm">
            {` (as `}
            <button
              className={`${
                !fieldState.showTextVersion ? 'text-text dark:text-text-dm' : ''
              } italic underline focus:outline-none`}
              onClick={(event) => setTextVersion(event, false)}
            >
              file
            </button>
            {` or `}
            <button
              className={`${
                fieldState.showTextVersion ? 'text-text dark:text-text-dm' : ''
              } italic underline focus:outline-none`}
              onClick={(event) => setTextVersion(event, true)}
            >
              text
            </button>
            {`) `}
          </em>
        )}
        {fieldState?.value ? (
          <button className="ml-1 text-text-35 dark:text-text-35-dm italic" onClick={(event) => removeFile(event)}>
            (<span className="underline">remove attachment</span>)
          </button>
        ) : null}
      </div>
      <div
        className={`relative ${
          hasCompanion
            ? `${
                fieldState.showTextVersion ? 'h-0 opacity-0 ease-out -mt-6' : 'h-32 py-5 ease-in'
              } overflow-hidden duration-200 transition-all`
            : ''
        } ${
          draggedOn
            ? 'border-blue-light dark:border-blue-light'
            : 'border-transparent hover:border-blue-light hover:dark:border-blue-light'
        } ${
          fieldState.error ? 'bg-text-50-bg2 border-opacity-40 border-red-md dark:bg-red-base-70' : ''
        } rounded-lg bg-body-bg2 dark:bg-white-bg-weak dark:bg-opacity-30 dark:text-gray-darker border-2 flex justify-center items-center px-5`}
        onDragEnter={() => setDraggedOn(true)}
      >
        <label
          className={`${
            draggedOn ? 'z-0' : 'z-20'
          } px-1 relative overflow-hidden inline-flex justify-center items-center flex-wrap font-inter font-normal cursor-pointer`}
          htmlFor={field.name}
        >
          {fieldState?.value ? (
            <>
              <span className="font-bold inline text-orange">Attached: </span>
              <span
                className="font-bold ml-1 md:block lg:inline-block max-w-200 overflow-ellipsis overflow-hidden text-right whitespace-nowrap"
                style={{ direction: 'rtl' }}
              >
                {fieldState?.value}
              </span>
              <button
                className="relative -top-px px-2 text-text-35  dark:text-text-35-dm hover:text-orange font-ilisarniq rounded-full outline-none focus:outline-none"
                onClick={(event) => removeFile(event)}
                title="Remove Attachment"
              >
                x
              </button>
            </>
          ) : (
            <>
              <span className="text-text-70 dark:text-text-70-dm inline md:underline opacity-60">Upload a File</span>
              <span className="text-text-70 dark:text-text-70-dm ml-1 hidden md:block lg:inline-block opacity-60">
                {' '}
                or Drag and Drop Here
              </span>
            </>
          )}
        </label>
        <input
          ref={ref}
          className="opacity-0 absolute z-10 inset-0 w-full cursor-pointer"
          id={field.name}
          type="file"
          accept="application/pdf,image/jpeg,image/png,application/vnd.apple.pages,application/msword,.doc,.docx"
          name={field.name}
          required={required}
          disabled={fieldState.showTextVersion}
          onDragEnter={() => setDraggedOn(true)}
          onDragLeave={() => setDraggedOn(false)}
          onDrop={() => setDraggedOn(false)}
          onChange={(event) => {
            if (event.target.files.length) {
              const reader = new FileReader()
              reader.onload = function () {
                const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
                updateInputState(`${field.name}_content`, base64String)
              }
              reader.readAsDataURL(event.target.files[0])
              updateInputState(`${field.name}_content_filename`, event.target.files[0].name)
            } else {
              updateInputState(`${field.name}_content`, '')
              updateInputState(`${field.name}_content_filename`, '')
            }
          }}
        />
      </div>
      <span className={`${fieldState.showTextVersion ? 'hidden' : ''}`}>
        <Errors error={fieldState.error} name={field.name} />
      </span>
    </fieldset>
  )
}

export const TextareaField = ({ field, label, required, inputState, updateInputState }) => {
  const fieldState = inputState[field.name]

  const fileVersionName = `${field.name.replace(/_text$/, '_content_filename')}`
  const showFileInstead = inputState[fileVersionName] ? !inputState[fileVersionName]?.showTextVersion : false
  return (
    <div
      className={`${
        showFileInstead ? 'max-h-0 opacity-0 ease-out' : 'max-h-268 ease-in'
      } overflow-hidden duration-200 transition-all`}
    >
      <fieldset className="relative mb-6">
        <textarea
          className={`${
            fieldState.error
              ? 'bg-text-50-bg2 border-opacity-40 border-red-md dark:bg-red-base-70'
              : 'dark:border-black-0.5 border-transparent'
          } resize-none min-h-200 caret-blue-caret flex justify-start items-center w-full font-inter font-normal rounded-lg py-2 px-4 placeholder-text-30 border-transparent dark:broder-white-bg-15 placeholder-opacity-70 duration-200 outline-none focus:outline-none focus:border-blue-light dark:focus:border-blue border-2 focus:duration-200 focus:border-blue-light bg-body-bg2 dark:bg-white-bg-weak dark:bg-opacity-30 dark:placeholder-white-clear dark:placeholder-opacity-40 dark:text-gray-darker`}
          id={field.name}
          name={field.name}
          placeholder={`${label}${required ? '*' : ''}`}
          aria-label={label}
          value={fieldState.value}
          required={required}
          disabled={showFileInstead}
          onChange={(event) => updateInputState(field.name, event.target.value)}
        />
        <Errors error={fieldState.error} name={field.name} />
      </fieldset>
    </div>
  )
}

export const TextField = ({ field, label, required, inputState, updateInputState }) => {
  const fieldState = inputState[field.name]
  return (
    <fieldset className="relative mb-6">
      <input
        className={`${
          fieldState.error
            ? 'bg-text-50-bg2 border-opacity-40 border-red-md dark:bg-red-base-70'
            : 'dark:border-black-0.5 border-transparent'
        } caret-blue-caret flex justify-start items-center w-full font-inter font-normal rounded-lg py-2 px-4 placeholder-text-30 placeholder-opacity-70 duration-200 outline-none focus:outline-none focus:border-blue-light dark:focus:border-blue border-2 focus:duration-200 focus:border-blue-light bg-body-bg2 dark:bg-white-bg-weak dark:bg-opacity-30 dark:placeholder-white-clear dark:placeholder-opacity-40 dark:text-gray-darker`}
        id={field.name}
        type="text"
        name={field.name}
        placeholder={`${label}${required ? '*' : ''}`}
        aria-label={label}
        required={required}
        value={fieldState.value}
        onChange={(event) => updateInputState(field.name, event.target.value)}
      />
      <Errors error={fieldState.error} name={field.name} />
    </fieldset>
  )
}

export const HiddenField = ({ field, label, required }) => {
  return (
    <fieldset className="relative mb-6">
      <input
        type="hidden"
        required={required}
        name={field.name}
        value={JSON.stringify(field.values)}
        aria-label={label}
      />
    </fieldset>
  )
}

export const SelectField = ({ field, label, required, inputState, updateInputState }) => {
  const fieldState = inputState[field.name]
  return (
    <fieldset className="relative mb-6">
      <Legend>{`${label}${required ? '*' : ''}`}</Legend>
      <div className="max-w-240 relative dark:text-gray-darker">
        <select
          className="appearance-none cursor-pointer caret-blue-caret flex justify-start items-center w-full font-inter font-normal rounded-lg py-2 px-4 placeholder-text-30 border-transparent dark:broder-white-bg-15 placeholder-opacity-70 duration-200 outline-none focus:outline-none focus:border-blue-light dark:focus:border-blue border-2 focus:duration-200 focus:border-blue-light bg-body-bg2 dark:bg-white-bg-weak dark:bg-opacity-30 dark:placeholder-white-clear dark:placeholder-opacity-40"
          id={field.name}
          name={field.name}
          value={fieldState.value}
          required={required}
          onChange={(event) => updateInputState(field.name, event.target.value)}
        >
          {field?.values.map((opt, i) => {
            return (
              <option key={i} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </select>
        <svg
          className="pointer-events-none	absolute top-1/2 right-3"
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m0 0 5 5 5-5H0Z" fill="currentColor" />
        </svg>
      </div>
    </fieldset>
  )
}

export const MultiSelectField = ({ field, label, required, inputState, updateInputState }) => {
  const fieldState = inputState[field.name]
  return (
    <fieldset className="relative mb-6">
      <Legend>{`${label}${required ? '*' : ''}`}</Legend>
      {field?.values.map((opt, i) => {
        return (
          <label key={i} className="block">
            <input
              type="checkbox"
              id={`${field.name}-${i}`}
              required={required}
              name={`${field.name}-${i}`}
              value={opt.value}
              onChange={(event) => {
                const newVal = fieldState?.value ? [...fieldState.value] : []
                const index = newVal.indexOf(opt.value)
                if (index === -1) {
                  newVal.push(opt.value)
                } else {
                  newVal.splice(index, 1)
                }
                updateInputState(field.name, newVal)
              }}
            />
            <span className="ml-1 dark:text-text-dm">{opt.label}</span>
          </label>
        )
      })}
    </fieldset>
  )
}

export const NoField = ({ field: { type } }) => {
  console.error(`Feild type "${type}" does not correspond to a component.`)
  return null
}
