import { useState } from 'react'

import { validateEmail } from '@/helpers'

type Errors = {
  email?: string
  message?: string
  followupCheckbox?: string
  newsletterCheckbox?: string
  submit?: string
  topicValues?: string
}

type UseContactFormState = {
  selectedTopicValues: string[]
  email: string
  message: string
  followup: boolean
  newsletter: boolean
  hutk: string
  pageUri: string
  pageName: string
  submitting: boolean
  success: boolean
  errors: Errors
}

type UseContactFormType = {
  formState: UseContactFormState
  onMultiselectChange: (value: string) => void
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onAnalyticsInit: (key: string, val: string) => void
  displayErrors: (errors: Errors) => void
  validate: () => boolean
  resetErrors: () => void
  changeSubmittingStatus: () => void
  setSuccessfulStatus: () => void
}

const useContactForm = (): UseContactFormType => {
  const [formState, changeFormState] = useState({
    selectedTopicValues: [],
    email: '',
    message: '',
    followup: false,
    newsletter: false,
    hutk: '',
    pageUri: '',
    pageName: '',
    submitting: false,
    success: false,
    errors: {
      topicValues: '',
      email: '',
      message: '',
      followupCheckbox: '',
      newsletterCheckbox: '',
      submit: '',
    },
  })

  function onAnalyticsInit(key: string, val: string) {
    changeFormState((prevState) => ({
      ...prevState,
      [key]: val,
    }))
  }

  const onMultiselectChange = (value: string) => {
    changeFormState((prevState) => ({
      ...prevState,
      selectedTopicValues: formState.selectedTopicValues.includes(value)
        ? prevState.selectedTopicValues.filter((field) => field !== value)
        : prevState.selectedTopicValues.concat(value),
    }))
  }

  function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    changeFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    changeFormState({
      ...formState,
      [event.target.name]: event.target.checked,
    })
  }

  function displayErrors(errors: Errors) {
    changeFormState({
      ...formState,
      errors: {
        ...formState.errors,
        ...errors,
      },
    })
  }

  function resetErrors() {
    changeFormState({
      ...formState,
      errors: {
        topicValues: '',
        email: '',
        message: '',
        followupCheckbox: '',
        newsletterCheckbox: '',
        submit: '',
      },
    })
  }

  function changeSubmittingStatus() {
    changeFormState({
      ...formState,
      submitting: !formState.submitting,
    })
  }

  function setSuccessfulStatus() {
    changeFormState({
      ...formState,
      success: true,
    })
  }

  function validate() {
    const { selectedTopicValues, email, followup, newsletter, message } = formState
    const errors: Errors = {
      email: '',
      message: '',
      followupCheckbox: '',
      newsletterCheckbox: '',
      topicValues: '',
    }
    let isValid = true

    if (!followup) {
      errors.followupCheckbox = 'This is required'
      isValid = false
    }
    if (!validateEmail(email)) {
      errors.email = 'Please provide valid Email Address'
      isValid = false
    }
    if (!message.length) {
      errors.message = 'Please provide provide a message'
      isValid = false
    }
    if (!selectedTopicValues.length) {
      errors.topicValues = 'Please check at least one option'
      isValid = false
    }

    displayErrors(errors)

    return isValid
  }

  return {
    formState,
    onMultiselectChange,
    onFieldChange,
    onAnalyticsInit,
    onCheckboxChange,
    displayErrors,
    resetErrors,
    changeSubmittingStatus,
    setSuccessfulStatus,
    validate,
  }
}

export default useContactForm
