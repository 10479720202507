import { Link, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'
import React, { useContext, useCallback } from 'react'

import { ThemeContext } from '@/context'
import { Image, Label, Subheading, ButtonPrimmary } from '@/uikit'

import * as styles from './styles.module.css'

type Box = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBodyService_boxes,
  | 'topic'
  | 'title'
  | 'reverse'
  | 'background'
  | 'markdown_content'
  | 'button_text'
  | 'button_slug'
  | 'alternative_accent_text'
> & {
  readonly accent: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
  readonly accent_dark_mode: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
}

const Blurb: React.FC<{ children: string }> = ({ children }) => (
  <div className="max-w-450 w-full mb-15">
    <span className="font-inter font-light leading-semiBig text-black-lighter text-lg dark:text-text-dm max-w-450">
      {children}
    </span>
  </div>
)

export const ServiceBoxesSection: React.FC<GatsbyTypes.ServiceBoxesSectionFragment> = ({ service_boxes }) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)

  const renderImageElement = useCallback(
    (accent, accent_dark, alt, reverse, index) => (
      <>
        <p
          className={`ani-child ani-no-fade " absolute font-ilisarniq font-bold leading-240 tracking-tightest text-orange-lighter text-100 md:text-200 z-10 -top-32 " ${
            reverse ? ' right-0 ani-left' : ' ani-right md:left-0 md:right-auto right-0 '
          }`}
        >
          {`${index + 1}.`}
        </p>
        <div className="ani-child ani-up ani-blur dark:hidden block max-w-450">
          <Image src={accent} alt={alt} />
        </div>
        <div className="ani-child ani-up ani-blur dark:block hidden max-w-450">
          <Image src={accent_dark} alt={alt} />
        </div>
      </>
    ),
    [theme]
  )

  const renderServiceBox = useCallback(
    (
      {
        title,
        background,
        reverse,
        accent,
        accent_dark_mode,
        alternative_accent_text,
        topic,
        markdown_content,
        button_slug,
        button_text,
      }: Box,
      index: number
    ) => {
      const backgroundVariant =
        (background === ' Right' && 'justify-end rounded-2xl md:rounded-l-2xl') ||
        (background === 'Left' && 'justify-start rounded-2xl md:rounded-r-2xl') ||
        (background === 'None' && 'justify-center')

      const containerVariant =
        (background === ' Right' &&
          'bg-white dark:bg-body-bg1-dm px-7.5 lg:pr-12 lg:pl-10 xl:pr-24 xl:pl-20 flex-col md:flex-row rounded-2xl md:rounded-l-2xl md:rounded-r-none py-20  md:py-33 w-full md:ml-95') ||
        (background === 'Left' &&
          'bg-white dark:bg-body-bg1-dm px-7.5 lg:pl-41 lg:pr-10 xl:pl-41 xl:pr-20 flex-col md:flex-row rounded-2xl md:rounded-r-2xl md:rounded-l-none py-20 md:py-33 w-full  md:mr-95') ||
        (background === 'None' && 'px-7.5 lg:px-150 xl:px-175 flex-col md:flex-row py-33 md:py-53 w-full')

      const contentVariant = reverse && ' md:flex-row-reverse'

      const slug = title
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')

      return (
        <li id={slug} key={topic} className="w-full">
          <div className={`${backgroundVariant} flex  w-full`}>
            <div
              className={` ${containerVariant} flex items-center justify-between 2xl:justify-center ${contentVariant}`}
            >
              <div className="ani-group md:block hidden relative  ">
                {renderImageElement(accent, accent_dark_mode, alternative_accent_text, reverse, index)}
              </div>
              <div
                className={` ${styles.text} ${
                  reverse ? '2xl:mr-44' : '2xl:ml-44'
                } flex flex-col justify-center items-start`}
              >
                <div className="mb-2.5">
                  <Label>{topic}</Label>
                </div>
                <div className=" mb-10 md:mb-20">{title && <Subheading bold>{title}</Subheading>}</div>
                <div className="ani-group md:hidden flex justify-center mb-10 relative ">
                  {renderImageElement(accent, accent_dark_mode, alternative_accent_text, reverse, index)}
                </div>
                {markdown_content && (
                  <Markdown
                    options={{
                      overrides: {
                        span: {
                          component: Blurb,
                        },
                      },
                    }}
                  >
                    {markdown_content}
                  </Markdown>
                )}
                {button_slug && button_text && (
                  <div className="w-auto mt-1 sm:mt-15 flex">
                    <Link to={button_slug}>
                      <ButtonPrimmary>{button_text}</ButtonPrimmary>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </li>
      )
    },
    [service_boxes]
  )

  return (
    <ul className="flex justify-center items-center flex-col w-full pt-15 pb-20">
      {service_boxes.map((box, index) => renderServiceBox(box, index))}
    </ul>
  )
}

export const serviceBoxesQuery = graphql`
  fragment ServiceBoxesSection on MarkdownRemarkFrontmatterBody {
    service_boxes {
      topic
      title
      reverse
      background
      markdown_content
      button_text
      button_slug
      accent {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
        extension
        publicURL
      }
      accent_dark_mode {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
        }
        extension
        publicURL
      }
      alternative_accent_text
    }
  }
`
