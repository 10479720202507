import { graphql } from 'gatsby'
import React from 'react'

import { CustomLink } from '@/components'
import { numberWithCommas } from '@/helpers'
import { Subheading, Text, Content, ButtonPrimmary } from '@/uikit'

export const OurRatesSection: React.FC<GatsbyTypes.OurRatesSectionFragment> = ({
  rates_title,
  rates_description,
  rates_offers,
}) => {
  return (
    <Content>
      <div className="flex items-center justify-center pb-15 pt-16.5">
        <div className="max-w-1440">
          <div className="text-center">
            <div className="mb-7.5">{rates_title && <Subheading bold>{rates_title}</Subheading>}</div>
            <div className="mb-17.5">
              <Text>{rates_description}</Text>
            </div>
          </div>
          <div className="flex items-center justify-center flex-wrap">
            {rates_offers &&
              rates_offers.map((offer) => (
                <div className="max-w-370 bg-white rounded-2xl md:mx-3.9 mb-7.5 dark:bg-body-bg1-dm" key={offer.title}>
                  <div className="pt-13 px-10">
                    <div className="border-opacity-20 border-b border-black-lighter mb-5 dark:border-white-clear dark:border-opacity-20 ">
                      <h3 className="font-ilisarniq text-32 leading-38 tracking-tightest text-text mb-2.5 dark:text-gray-darker ">
                        {offer.title}
                      </h3>
                      <p className="font-inter font-light text-base leading-small text-black-lighter mb-5 dark:text-text-dm">
                        {offer.lead}
                      </p>
                    </div>
                  </div>

                  <div className="pb-10 flex items-center justify-center">
                    {offer.links &&
                      offer.links.map((link) => (
                        <div className="max-w-130 w-full" key={link.title}>
                          <CustomLink type={link.type} url={link.url} newTab={link.new_tab || false}>
                            <ButtonPrimmary>{link.title}</ButtonPrimmary>
                          </CustomLink>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Content>
  )
}

export const ourRatesSectionQuery = graphql`
  fragment OurRatesSection on MarkdownRemarkFrontmatterBody {
    rates_title
    rates_description
    rates_offers {
      title
      lead
      links {
        title
        url
        type
        new_tab
      }
    }
  }
`
