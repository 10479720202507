import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { FunctionComponent } from 'react'

import { capitalizeFirstLetter, formatDate } from '@/helpers/utils'
import { Image, Label } from '@/uikit'

export const FeaturedPosts: FunctionComponent<GatsbyTypes.FeaturedPostsFragment> = ({ label, heading }) => {
  const featuredPosts = useStaticQuery<GatsbyTypes.Last5PostsQuery>(graphql`
    query Last5Posts {
      list: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/blog/.*/" } }
        sort: { fields: frontmatter___date_of_publication, order: DESC }
        limit: 5
      ) {
        edges {
          node {
            id
            frontmatter {
              url
              title
              author
              date_of_publication
              post_categories
              bp_image_light {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.77, formats: [WEBP])
                }
                extension
                publicURL
              }
              bp_image_dark {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.77, formats: [WEBP])
                }
                publicURL
                extension
              }
              excerpt
            }
          }
        }
      }
    }
  `)
  const { list } = featuredPosts || {}
  const latestPost = list.edges?.length ? list.edges[0].node : null

  return (
    <section className="pt-12.5 pb-16 lg:pt-30">
      <div className="max-w-full   flex justify-center">
        <div className="max-w-540  lg:max-w-1060">
          <div className="mb-10.5 mx-7.5 sm:mx-0 lg:mx-7.5 lg:mb-20">
            <Label>{label}</Label>
            <h1 className="my-4 text-text text-40 dark:text-white font-ilisarniq font-bold leading-big tracking-tightest lg:text-5xl">
              {heading}
            </h1>
          </div>

          {latestPost && (
            <div className="flex flex-col lg:flex-row">
              <div className="flex-1 flex-shrink-0 sm:mx-0 lg:mx-0 lg:ml-7.5">
                <Link to={latestPost ? `/blog/${latestPost.frontmatter.url}` : '/'} className="group">
                  <div className="bg-body-bg2 dark:bg-body-bg1-dm max-w-full h-270 bg-body-bg2 flex lg:rounded-2xl lg:mb-3 overflow-hidden">
                    {latestPost.frontmatter.bp_image_light && (
                      <div className="ani ani-blur ani-scale flex-1 outline-black dark:hidden ">
                        <Image
                          src={latestPost.frontmatter.bp_image_light}
                          alt="post image"
                          className="h-full w-full object-cover block transition-transform duration-500 hover:scale-105 transform"
                        />
                      </div>
                    )}
                    {latestPost.frontmatter.bp_image_dark && (
                      <div className="ani ani-blur ani-scale flex-1 outline-black dark:block hidden">
                        <Image
                          src={latestPost.frontmatter.bp_image_dark}
                          alt="post image"
                          className="h-full w-full object-cover transition-transform duration-500 hover:scale-105 transform"
                        />
                      </div>
                    )}
                  </div>
                  <div className="bg-white dark:bg-body-bg1-dm px-7.5 pb-10.5 pt-5.5 lg:rounded-2xl lg:pt-7.5 lg:pb-8">
                    <div className="text-black-lighter dark:text-text-70-dm font-inter font-light">
                      {formatDate(latestPost.frontmatter.date_of_publication)} |{' '}
                      {latestPost.frontmatter.post_categories?.map((item) => capitalizeFirstLetter(item)).join(' | ')} |{' '}
                      {latestPost.frontmatter.author}
                    </div>
                    <h2 className="my-3 font-normal text-text dark:text-white text-32 leading-9 font-ilisarniq tracking-tightest dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                      {latestPost.frontmatter.title}
                    </h2>
                    <div className="text-black-lighter dark:text-text-70-dm font-inter font-light leading-normal text-lg">
                      {latestPost.frontmatter?.excerpt}
                    </div>
                  </div>
                </Link>
              </div>
              <ul
                data-ani-stagger="50"
                className="ani-group mx-7.5 sm:mx-0 lg:mx-7.5 lg:flex lg:flex-col lg:justify-between lg:pl-18 lg:w-5/12 flex-shrink-0"
              >
                {list?.edges &&
                  list.edges.slice(1, 5).map(({ node: { id, frontmatter } }) => {
                    const parsedPostCategories = frontmatter.post_categories
                      ?.map((item) => capitalizeFirstLetter(item))
                      .join(' | ')
                    return (
                      <li key={id} className="mt-15.5 lg:mt-0 lg:mb-7.5 block">
                        <Link to={`/blog/${frontmatter.url}`} className="lg:flex lg:gap-30 group">
                          <div className="self-start lg:w-32 w-full bg-body-bg2 dark:bg-body-dm rounded-semiXl overflow-hidden">
                            {frontmatter.bp_image_light && (
                              <div className="ani-child ani-blur ani-scale dark:hidden flex-col max-w-full min-h-200 lg:min-h-full lg:h-106 flex items-center justify-center">
                                <Image
                                  src={frontmatter.bp_image_light}
                                  alt="post image"
                                  className="flex-1 block rounded-semiXl object-cover transition-transform duration-500 hover:scale-110 transform"
                                />
                              </div>
                            )}
                            {frontmatter.bp_image_dark && (
                              <div className="ani-child ani-blur ani-scale dark:flex bg-body-bg1-dm flex-col hidden max-w-full min-h-200 lg:min-h-full lg:h-106 items-center justify-center">
                                <Image
                                  src={frontmatter.bp_image_dark}
                                  alt="post image"
                                  className="flex-1 block rounded-semiXl object-cover transition-transform duration-500 hover:scale-110 transform"
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex-1">
                            <div className="text-black-lighter dark:text-text-70-dm font-inter font-light leading-5 my-4 lg:my-0 lg:mb-4">
                              {formatDate(frontmatter.date_of_publication)} | {parsedPostCategories} |{' '}
                              {frontmatter.author}
                            </div>
                            <div className="font-semibold  font-inter ">
                              <h3 className="leading-medium text-lg text-black-lighter dark:text-white dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
                                {frontmatter.title}
                              </h3>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const featuredPostsQuery = graphql`
  fragment FeaturedPosts on MarkdownRemarkFrontmatterBody {
    label
    heading
  }
`
