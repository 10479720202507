import { LOCALSTORAGE_THEME_KEY, themes } from '@/consts'

export const handleGlobalThemeChange: (isDarkMode: boolean) => void = (isDarkMode) => {
  if (isDarkMode) {
    document.documentElement.classList.add(themes.dark)
    localStorage.setItem(LOCALSTORAGE_THEME_KEY, themes.dark)
  } else {
    document.documentElement.classList.remove(themes.dark)
    localStorage.setItem(LOCALSTORAGE_THEME_KEY, '')
  }
}
