import React, { useState } from 'react'

import { validateEmail } from '@/helpers'
import { ButtonPrimmary } from '@/uikit'

import {
  FileField,
  TextField,
  HiddenField,
  TextareaField,
  SelectField,
  MultiSelectField,
  NoField,
} from './components/formFields'

const validate = (inputState, updateInputState) => {
  let isValid = true

  for (const inputName in inputState) {
    const input = inputState[inputName]
    if (inputName === 'id') {
      continue
    } else if (input.type === 'file_data') {
      continue
    } else if (input.label === 'Email' && input.value && !validateEmail(input.value)) {
      updateInputState(inputName, 'This email is invalid', 'error')
      isValid = false
    } else if (input.required && !input.value) {
      if (input.type === 'input_file') {
        const companionField = inputState[inputName.replace(/_content_filename$/, '_text')]
        if (!companionField || !companionField.value) {
          updateInputState(inputName, 'This field is required', 'error')
          isValid = false
        } else {
          updateInputState(inputName, '', 'error')
        }
      } else if (input.type === 'textarea') {
        const companionField = inputState[inputName.replace(/_text$/, '_content_filename')]
        if (!companionField || !companionField.value) {
          updateInputState(inputName, 'This field is required', 'error')
          isValid = false
        } else {
          updateInputState(inputName, '', 'error')
        }
      } else {
        updateInputState(inputName, 'This field is required', 'error')
        isValid = false
      }
    } else {
      updateInputState(inputName, '', 'error')
    }
  }

  return isValid
}

const Message = ({ children, className }) => {
  return <div className={`text-center leading-tight px-2 py-10 rounded-md ${className}`}>{children}</div>
}

export const ApplyForm: React.FC<Props> = ({ id, formFields }) => {
  let initialInputState = { id }
  formFields?.forEach((fieldGroup) => {
    fieldGroup?.fields?.forEach((field) => {
      initialInputState = {
        ...initialInputState,
        [`${field.name}${field.type === 'input_file' ? '_content_filename' : ''}`]: {
          value: field.values.length ? `${field.values[0].value}` : '',
          error: '',
          required: fieldGroup.required,
          label: fieldGroup.label,
          type: field.type,
        },
      }
      if (field.type === 'input_file') {
        initialInputState[`${field.name}_content`] = { value: '', type: 'file_data' }
      }
    })
  })
  const [inputState, setInputState] = useState(initialInputState)
  const updateInputState = (key, value, subKey = 'value') => {
    setInputState((prevState) => {
      return { ...prevState, [key]: { ...prevState[key], [subKey]: value } }
    })
  }
  const [submitStatus, setSubmitStatus] = useState({ status: 'waiting', button: 'Submit Application', message: '' })

  const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (validate(inputState, updateInputState)) {
      setSubmitStatus({ status: 'submitting', button: 'Submitting', message: '' })
      const submitData = {}
      for (const inputName in inputState) {
        if (inputState[inputName]?.value) {
          submitData[inputName] = inputState[inputName].value
        }
      }
      submitData.id = inputState.id
      fetch('/.netlify/functions/jobapplicationform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submitData),
      })
        .then((response) => {
          if (response.status === 200) {
            setSubmitStatus({
              status: 'sent',
              button: 'Sent!',
              message: 'Your application has been submitted.',
            })
          } else {
            setSubmitStatus({
              status: 'error',
              button: 'Submission Error',
              message: 'There was an error with your submission: please try again later.',
            })
            document &&
              document.getElementById('application-form').scrollIntoView({ behavior: 'smooth', block: 'start' })
            console.error(response)
          }
          return response.text()
        })
        .catch(console.log)
    } else {
      setSubmitStatus({
        status: 'invalid',
        button: 'Resubmit',
        message: "Please resubmit your application after you've corrected the errors above.",
      })
      document && document.getElementById('application-form').scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const fieldTypes = {
    input_file: FileField,
    input_text: TextField,
    input_hidden: HiddenField,
    textarea: TextareaField,
    multi_value_single_select: SelectField,
    multi_value_multi_select: MultiSelectField,
  }

  return (
    <form onSubmit={onSubmit} noValidate id="application-form" className="pt-10 -mt-10 mb-24">
      {['waiting', 'invalid'].includes(submitStatus.status) && (
        <>
          <h2 className="dark:text-text-dm text-lg font-bold mb-4 -mt-10">About</h2>
          {formFields.map((fieldGroup: object, i: number) => {
            return (
              <div key={i}>
                {fieldGroup?.fields.map((field: object, i: number) => {
                  const FieldComponent = typeof fieldTypes[field.type] === 'function' ? fieldTypes[field.type] : NoField
                  return (
                    <FieldComponent
                      field={field}
                      label={fieldGroup?.label}
                      required={fieldGroup?.required}
                      inputState={inputState}
                      updateInputState={updateInputState}
                      key={i}
                    />
                  )
                })}
              </div>
            )
          })}
          <div className="w-64 mx-auto mt-24">
            <ButtonPrimmary type="submit" disabled={['error', 'sent'].includes(submitStatus.status)} hasArrow={false}>
              {submitStatus.button}
            </ButtonPrimmary>
          </div>
        </>
      )}
      {submitStatus.status === 'error' && (
        <Message className="bg-red bg-opacity-10 text-red">{submitStatus.message}</Message>
      )}
      {submitStatus.status === 'invalid' && (
        <Message className="bg-red bg-opacity-10 text-red mt-10">{submitStatus.message}</Message>
      )}
      {submitStatus.status === 'sent' && (
        <Message className="bg-green bg-opacity-10 text-green-400">{submitStatus.message}</Message>
      )}
    </form>
  )
}
