import { graphql } from 'gatsby'
import React from 'react'

import { Content, Image } from '@/uikit'

export const PartnersSection: React.FC<GatsbyTypes.PartnersSectionFragment> = ({ header, companies }) => {
  return (
    <div className="pb-16.5">
      <Content>
        <div className="flex w-full justify-center items-center">
          <div className="max-w-1440 w-full">
            <p className="w-full text-center text-lg font-ilisarniq font-normal	leading-medium mb-10 dark:text-gray-darker text-text">
              {header}
            </p>
            <ul
              data-ani-stagger="50"
              className="ani-group flex items-center justify-center lg:justify-between flex-wrap w-full w-full"
            >
              {companies?.map((company, index: number) => (
                <li
                  key={`companyBox_${index}`}
                  className={`ani-child ani-blur ani-scale my-8 h-16.5 flex px-2 items-center sm:justify-center w-1/2 sm:w-1/3 align-middle ${
                    index % 2 === 1 && 'justify-end'
                  }`}
                >
                  <div className="max-w-150 max-h-60">
                    <div className="dark:hidden block">
                      <Image src={company.image && company.image} alt={company.alternative_text || ''} />
                    </div>
                    <div className="dark:block hidden">
                      <Image
                        src={company.image_dark_mode && company.image_dark_mode}
                        alt={company.alternative_text || ''}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Content>
    </div>
  )
}

export const partnersSectionQuery = graphql`
  fragment PartnersSection on MarkdownRemarkFrontmatterBody {
    header
    companies {
      alternative_text
      image {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 80, formats: [WEBP])
        }
        extension
        publicURL
      }
      image_dark_mode {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 80, formats: [WEBP])
        }
        extension
        publicURL
      }
    }
  }
`
