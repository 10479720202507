export const signUpToNewsletter = (data: {
  email: string
  checked: boolean
  hutk: string
  pageUri: string
  pageName: string
}): Promise<void> => {
  const prom = fetch('/.netlify/functions/newsletter', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.text())
    .catch(console.log)
  return prom
}

export const sendContactForm = (data: {
  email: string
  message: string
  hutk: string
  pageUri: string
  pageName: string
  followup: boolean
  newsletter: boolean
  topic: string
}): Promise<void> => {
  const prom = fetch('/.netlify/functions/contactform', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.text())
    .catch(console.log)
  return prom
}

export const sendRateCardForm = (data: {
  name: string
  phone: string
  company: string
  email: string
  message: string
  hutk: string
  pageUri: string
  pageName: string
}): Promise<void> => {
  const prom = fetch('/.netlify/functions/ratecardform', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.text())
    .catch(console.log)
  return prom
}
