import { State, Action, ACTIONS } from './types'

export function layoutReducer(state: State, action: Action) {
  switch (action.type) {
    case ACTIONS.toggleMobileMenu: {
      return {
        mobileMenu: !state.mobileMenu,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
