export enum THEMES {
  light = 'light',
  dark = 'dark',
}

export enum ACTIONS {
  toggle = 'toggle',
  setTheme = 'setTheme',
}

export type State = {
  theme: THEMES.dark | THEMES.light
}

export type Action = { type: ACTIONS.toggle } | { type: ACTIONS.setTheme; payload: THEMES }
