import { graphql } from 'gatsby'
import React, { useCallback, useContext } from 'react'

import { CustomLink } from '@/components'
import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { Label, Heading, Text, ButtonPrimmary, ButtonSecondary, Image, Content } from '@/uikit'

export const HeroSecondaryVariant: React.FC<GatsbyTypes.HeroSecondaryVariantFragment> = ({
  cta_label,
  cta_header,
  cta_description,
  cta_link_list = [],
  cta_image_light_mode,
  cta_image_dark_mode,
}) => {
  const {
    state: { theme },
  } = useContext(ThemeContext)
  const isDark = theme === themes.dark
  const overOneLink = cta_link_list.length > 1

  const ctaImageRender = useCallback(
    () =>
      cta_image_light_mode &&
      cta_image_dark_mode && (
        <div>
          <Image src={isDark ? cta_image_dark_mode : cta_image_light_mode} alt="CTA image" />
        </div>
      ),
    [isDark]
  )

  return (
    <Content>
      <div className="flex justify-center">
        <div className="flex justify-between items-center flex-col md:flex-row md:pl-20 pt-13 md:pt-30 pb-16.5 md:pb-15 max-w-1440 w-full">
          <div className="md:max-w-450 w-full flex flex-col items-start justify-center">
            <div className="mb-2.5">
              <Label>{cta_label}</Label>
            </div>
            <div className="mb-7.5">
              <Heading>{cta_header}</Heading>
            </div>
            <div className="mb-7.5">
              <Text>{cta_description}</Text>
            </div>
            {!overOneLink && (
              <div
                className={`${
                  !overOneLink && ' flex md:hidden'
                } ani ani-scale min-h-230 md:min-w-0 md:min-h-0 flex items-center justify-center mb-7.5`}
              >
                {ctaImageRender()}
              </div>
            )}
            <div className="flex flex-col md:flex-row w-full mb-7.5 md:mb-0">
              {cta_link_list &&
                cta_link_list.map((link, index) => (
                  <div key={link.title} className="w-auto mx-0 md:mx-4 my-4 md:my-0 md:first:ml-0">
                    <CustomLink url={link.url} newTab={link.new_tab || false} type={link.type}>
                      {index === 0 ? (
                        <ButtonPrimmary>{link.title}</ButtonPrimmary>
                      ) : (
                        <ButtonSecondary>{link.title}</ButtonSecondary>
                      )}
                    </CustomLink>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={`${
              !overOneLink && ' hidden md:flex'
            } ani ani-scale min-w-540 min-h-230 md:min-w-0 md:min-h-0 md:pl-0 pl-5 flex items-center justify-center`}
          >
            {ctaImageRender()}
          </div>
        </div>
      </div>
    </Content>
  )
}

export const heroSecondaryVariantQuery = graphql`
  fragment HeroSecondaryVariant on MarkdownRemarkFrontmatterBody {
    cta_label
    cta_header
    cta_description
    cta_link_list {
      title
      url
      type
      new_tab
    }
    cta_image_light_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 610, formats: [WEBP])
      }
      extension
      publicURL
    }
    cta_image_dark_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 610, formats: [WEBP])
      }
      extension
      publicURL
    }
  }
`
