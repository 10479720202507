import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { sendContactForm } from '@/helpers'
import { FormField, Topic, Checkbox, ButtonPrimmary } from '@/uikit'

import * as styles from './styles.module.css'
import useContactUsForm from './useContactUsForm'

type Props = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatterBody,
  'textarea_placeholder' | 'checkbox_text' | 'checkbox_text_2' | 'input_placeholder' | 'button_text'
> & {
  readonly topic_checkbox_fields: GatsbyTypes.Maybe<
    ReadonlyArray<Pick<GatsbyTypes.MarkdownRemarkFrontmatterBodyTopic_checkbox_fields, 'value' | 'label'>>
  >
  readonly contact_social_media: GatsbyTypes.Maybe<
    ReadonlyArray<
      Pick<
        GatsbyTypes.MarkdownRemarkFrontmatterBodyContact_social_media,
        'alternative_text' | 'name' | 'link' | 'open_in_new_tab'
      > & { readonly icon: GatsbyTypes.Maybe<Pick<GatsbyTypes.File, 'id' | 'extension' | 'publicURL'>> }
    >
  >
}

export const ContactUsForm: React.FC<Props> = ({
  topic_checkbox_fields,
  textarea_placeholder,
  checkbox_text,
  checkbox_text_2,
  input_placeholder,
  button_text,
}) => {
  const { formState, onMultiselectChange, onFieldChange, onCheckboxChange, onAnalyticsInit, validate } =
    useContactUsForm()
  const { email, message, followup, newsletter, errors, selectedTopicValues, hutk, pageUri, pageName } = formState

  const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    const isValid = validate()
    if (isValid) {
      sendContactForm({
        email,
        message,
        followup,
        newsletter,
        hutk,
        pageUri,
        pageName,
        topic: selectedTopicValues?.join(', '),
      })
        .then((data) => {
          const response = JSON.parse(data)
          if (response?.inlineMessage) {
            navigate('/message-sent')
          } else {
            console.error(response?.message, response?.errors)
            alert(
              `Error: ${response?.message}. ${
                response?.errors ? `${response?.errors.map((error) => error.message).join(', ')}.` : ''
              }`
            )
          }
        })
        .catch((error) => {
          alert(error)
        })
    }
  }

  useEffect(() => {
    onAnalyticsInit('hutk', document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'))
    onAnalyticsInit('pageUri', document.location.href)
    onAnalyticsInit('pageName', document.location.pathname)
  }, [email])

  return (
    <form id="contact" name="contact-form" method="POST" data-netlify="true" onSubmit={onSubmit} className="max-w-450">
      <ul className={`${styles.topicCheckbox} mb-7.5`}>
        {topic_checkbox_fields &&
          topic_checkbox_fields?.map((topic, index) => (
            <Topic
              {...topic}
              key={index}
              id={`contact-form-option-${index}`}
              checked={selectedTopicValues.includes(topic.value || '')}
              onSelect={onMultiselectChange}
            />
          ))}
        {errors.topicValues && (
          <span className="pl-4 font-inter font-normal text-red text-xs text-opacity-50">{errors.topicValues}</span>
        )}
      </ul>
      <div className={`${styles.textareaSize} mb-7.5 h-164`}>
        <FormField
          textarea
          id="contact-textarea"
          name="message"
          value={message}
          onChange={onFieldChange}
          placeholder={textarea_placeholder}
          error={errors.message}
          secondaryVariant
        />
      </div>
      <div className="mb-7.5">
        <FormField
          id="contact-input"
          name="email"
          value={email}
          onChange={onFieldChange}
          placeholder={input_placeholder}
          error={errors.email}
          secondaryVariant
        />
      </div>
      <div className="mb-7.5">
        <Checkbox
          id="confirm-followup-contact"
          name="followup"
          value={followup}
          onChange={onCheckboxChange}
          error={errors.followupCheckbox}
        >
          {checkbox_text || ''}
        </Checkbox>
      </div>
      <div className="mb-7.5">
        <Checkbox
          id="confirm-email-signup"
          name="newsletter"
          value={newsletter}
          onChange={onCheckboxChange}
          error={errors.newsletterCheckbox}
        >
          {checkbox_text_2 || ''}
        </Checkbox>
      </div>
      <ButtonPrimmary hasArrow={false} type="submit">
        {button_text || ''}
      </ButtonPrimmary>
    </form>
  )
}
