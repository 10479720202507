import { Link, graphql } from 'gatsby'
import React from 'react'

import { CustomLink } from '@/components'
import { Image } from '@/uikit'

import * as style from './styles.module.css'

export type NavigationTypes = GatsbyTypes.Maybe<
  ReadonlyArray<Pick<GatsbyTypes.MarkdownRemarkFrontmatterPrimary_navigation, 'title' | 'new_tab' | 'type' | 'url'>>
>

const NavigationBlock: React.FC<{
  title?: string
  data?: NavigationTypes
}> = ({ title, data }) => {
  return (
    <div>
      <p className="uppercase font-normal text-white text-sm font-ilisarniq  leading-medium tracking-tighter mb-5">
        {title}
      </p>
      <ul className="flex flex-col">
        {data?.map((navigationItem) => (
          <li className="mb-2" key={navigationItem.title}>
            <CustomLink
              url={`/${navigationItem.url !== '/' ? navigationItem.url : ''}`}
              type={navigationItem.type}
              newTab={navigationItem.new_tab || false}
            >
              <p className="font-inter font-light text-sm text-text-70-dm tracking-wider whitespace-nowrap hover:text-blue-light transition-colors">
                {navigationItem?.title}
              </p>
            </CustomLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const Footer: React.FC<GatsbyTypes.FooterFragment> = ({
  address,
  copyright,
  email,
  logo,
  mobile_accent,
  primary_accent,
  primary_navigation,
  primary_navigation_title,
  secondary_accent,
  secondary_navigation,
  secondary_navigation_title,
  social_media,
  alternative_logo_text,
  alternative_accent_text,
}) => {
  return (
    <footer className="flex overflow-hidden bg-body-bg1-dm relative z-20 min-h-410 print:hidden">
      {primary_accent && (
        <div data-ani-reset className="ani ani-mask-right z-0 hidden absolute bottom-0 left-0 w-72 md:block ">
          <Image src={primary_accent} alt={alternative_accent_text || ''} />
        </div>
      )}
      {mobile_accent && (
        <div data-ani-reset className="ani ani-mask-down block absolute -top-10 right-1.5 md:hidden">
          <Image src={mobile_accent} alt={alternative_accent_text || ''} />
        </div>
      )}
      <div className="relative z-10 px-7.5 sm:px-12 lg:px-28 xl:px-41  max-w-1920 w-full ml-auto mr-auto">
        <div className="flex flex-col pt-10 pl-4 md:pt-20 pr-8 ">
          <div className="flex justify-between flex-row flex-wrap">
            <div className="w-full md:w-auto">
              {logo && (
                <Link to="/" aria-label="Home">
                  <Image
                    src={logo}
                    alt={alternative_logo_text || ''}
                    className="hover:opacity-75 transition-opacity"
                    width="156"
                    height="32"
                  />
                </Link>
              )}
              {address && (
                <p
                  dangerouslySetInnerHTML={{ __html: address }}
                  className="text-text-70-dm font-inter font-light text-sm dark:bg-transparent justify-start mt-8 md:mt-9 md:ml-1 leading-medium tracking-tighter mb-2.5"
                />
              )}
              {email && (
                <a
                  href={`mailto:${email}`}
                  className="text-blue-light font-inter font-light text-sm mt-3 md:ml-1 hover:text-text-70-dm transition-colors"
                >
                  {email}
                </a>
              )}
            </div>
            <div className="md:pt-2 md:ml-10 pt-16 md:w-1/5">
              <NavigationBlock data={primary_navigation} title={primary_navigation_title} />
            </div>

            <div className="md:pt-2 md:ml-8 pt-16 md:w-1/3 w-1/2">
              <NavigationBlock data={secondary_navigation} title={secondary_navigation_title} />
            </div>
            <div className="hidden md:block" />
          </div>
        </div>
        <div className="flex-end flex flex-col-reverse justify-between py-12 md:flex-row md:mt-6 md:ml-2">
          {copyright && (
            <p className="z-10 pl-3 mt-12 md:mt-0 text-xs font-ilisarniq font-normal text-white tracking-tighter">
              {copyright}
            </p>
          )}
          {social_media && (
            <ul className="flex z-20 ">
              {social_media?.map((mediaItem) => {
                return (
                  <li
                    key={mediaItem.name}
                    className="mx-3 last:mr-0.3 z-10 w-2 relative flex justify-center items-center md:pr-2.5 pl-2"
                  >
                    <a
                      title={mediaItem.name}
                      href={mediaItem.link}
                      className="flex justify-center items-center z-10 transition-transform hover:scale-125 transform"
                    >
                      <Image
                        src={mediaItem.icon}
                        alt={mediaItem.alternative_text || ''}
                        className={`${style.icons} "w-4 h-4 max-w-xs z-20 "`}
                        width="12"
                        height="12"
                      />
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
      {secondary_accent && (
        <div
          data-ani-reset
          className="ani ani-left ani-blur hidden absolute z-0 -top-5 -right-32 xl:-right-23  md:block w-1/3 h-auto min-h-full"
        >
          <Image src={secondary_accent} alt={alternative_accent_text || ''} />
        </div>
      )}
    </footer>
  )
}

export const footerQuery = graphql`
  fragment Footer on MarkdownRemarkFrontmatter {
    alternative_logo_text
    alternative_accent_text
    logo {
      extension
      publicURL
    }
    primary_accent {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    secondary_accent {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    mobile_accent {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    address
    email
    primary_navigation_title
    primary_navigation {
      title
      new_tab
      type
      url
    }
    secondary_navigation_title
    secondary_navigation {
      title
      new_tab
      type
      url
    }
    copyright
    social_media {
      alternative_text
      name
      icon {
        id
        extension
        publicURL
      }
      link
      open_in_new_tab
    }
  }
`
