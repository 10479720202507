import React from 'react'

import { ButtonArrowWrap } from './buttonArrow'

interface ButtonProps {
  onClick?: () => void | Promise<void>
  disabled?: boolean
  hasArrow?: boolean
  type?: 'button' | 'submit' | 'reset'
  children: string
  className?: string
}

export const ButtonPrimmary: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  hasArrow = true,
  className = 'duration-300 w-full inline-flex font-ilisarniq font-bold not-italic justify-center py-2 px-8 border border-transparent h-12 items-center rounded-3xl shadow-sm text-base text-white-clear dark:text-white bg-blue focus:outline-none focus:ring-4 md:py-3 hover:bg-orange',
  children,
  type = 'button',
}) => {
  return (
    <button aria-label={children} onClick={onClick} type={type} disabled={disabled} className={`group ${className}`}>
      {hasArrow ? <ButtonArrowWrap children={children} arrowClassName="group-hover:text-white" /> : <>{children}</>}
    </button>
  )
}
