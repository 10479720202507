import { Link, graphql } from 'gatsby'
import React, { useContext } from 'react'

import { ThemeToggler, CustomLink, BackToTop } from '@/components'
import { themes } from '@/consts'
import { ThemeContext, LayoutContext, toggleLayout } from '@/context'
import { HamburgerIcon } from '@/icons'
import { Image } from '@/uikit'

import * as styles from './styles.module.css'

export const Header: React.FC<GatsbyTypes.HeaderFragment> = ({
  links,
  logo,
  logo_dark_mode,
  alternative_logo_text,
}) => {
  const { dispatch } = useContext(LayoutContext)
  const {
    state: { theme },
  } = useContext(ThemeContext)

  return (
    <header className="bg-white flex justify-between items-center pl-5 pr-5 md:pr-3 dark:bg-body-bg1-dm h-22 print:hidden">
      <BackToTop />
      <Link
        to="/"
        className={` ${styles.logo} flex justify-start items-center w-auto md:w-1/4 hover:opacity-60 dark:hover:opacity-70 transition-opacity`}
        aria-label="Home"
      >
        {logo && logo_dark_mode && (
          <Image
            src={theme === themes.dark ? logo_dark_mode : logo}
            alt={alternative_logo_text || ''}
            width="153"
            height="40"
          />
        )}
      </Link>
      <nav className="flex justify-center pl-1 self-stretch">
        <ul className="hidden md:flex justify-center w-1/2">
          {links &&
            links.map((link) => (
              <li key={link.title} className="mx-1 lg:mx-6 whitespace-nowrap flex">
                <CustomLink
                  url={`/${link.url}`}
                  type={link.type}
                  newTab={link.new_tab || false}
                  activeClassName={styles.active}
                  className={`${styles.menuLink} relative items-center border-orange flex duration-500 text-black-lighter dark:text-text-dm hover:text-text-35 dark:hover:text-text-35-dm"`}
                >
                  <p className="px-2 font-ilisarniq font-normal tracking-wide">{link?.title}</p>
                </CustomLink>
              </li>
            ))}
        </ul>
      </nav>
      <div className="hidden md:flex justify-end items-center w-1/4 pr-3">
        <ThemeToggler id="toggle-header-menu" />
      </div>
      <div className="flex justify-center items-center md:hidden">
        <button aria-label="hamburger" onClick={() => dispatch(toggleLayout())} type="button">
          <HamburgerIcon />
        </button>
      </div>
    </header>
  )
}

export const query = graphql`
  fragment Header on MarkdownRemarkFrontmatter {
    logo {
      extension
      publicURL
    }
    logo_dark_mode {
      publicURL
      extension
    }
    accent_mobile {
      childImageSharp {
        gatsbyImageData(width: 280, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    accent_mobile_dark_mode {
      childImageSharp {
        gatsbyImageData(width: 280, placeholder: BLURRED, formats: [WEBP])
      }
      extension
      publicURL
    }
    links {
      title
      new_tab
      type
      url
    }
  }
`
