import { globalHistory } from '@reach/router'
import React, { useState, useEffect, Dispatch } from 'react'

import { toggleLayout } from './action'
import { layoutReducer } from './reducer'
import { Action, State } from './types'

const windowGlobal = typeof window !== 'undefined' ? window : undefined
const historyGlobal = typeof history !== 'undefined' ? history : undefined
export const LAYOUT_INITIAL_STATE: State = {
  mobileMenu: false,
}

export const LayoutContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: LAYOUT_INITIAL_STATE,
  dispatch: () => null,
})

export const LayoutProvider: React.FC = ({ children }) => {
  const [width, setWidth] = useState(0)
  const [state, dispatch] = React.useReducer(layoutReducer, LAYOUT_INITIAL_STATE)

  useEffect(() => {
    if (windowGlobal) {
      windowGlobal.addEventListener('resize', () => setWidth(windowGlobal.innerWidth))
    }
    return () => {
      if (windowGlobal) {
        windowGlobal.removeEventListener('resize', () => setWidth(windowGlobal.innerWidth))
      }
    }
  }, [])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && state.mobileMenu) dispatch(toggleLayout())
    })
  }, [historyGlobal?.length, state.mobileMenu])

  useEffect(() => {
    if (width > 768 && state.mobileMenu === true) {
      dispatch(toggleLayout())
    }
  }, [windowGlobal?.innerWidth])

  useEffect(() => {
    const body = document && document.querySelector('body')

    if (state.mobileMenu) {
      if (body) {
        body.style.overflow = 'hidden'
      }
    } else if (body) {
      body.style.overflow = 'auto'
    }
  }, [state.mobileMenu])

  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>
}
