import { graphql } from 'gatsby'
import React from 'react'

import { MarkdownComponent } from '@/components'
import { Image } from '@/uikit'

export const IconList: React.FC<GatsbyTypes.StatisticsFragment> = ({ icon_list }) => {
  return (
    <div className="bg-white dark:bg-black-lighter mb-24">
      <div
        data-ani-stagger="100"
        className="ani-group max-w-1250 mx-auto py-18 px-5 md:px-12 mt-30 grid md:grid-cols-3 md:gap-10"
      >
        {icon_list.map((item, i) => {
          return (
            <div key={i} className="relative mb-15.5 md:mb-0 w-full last:mb-0">
              {item.icon && (
                <div className="absolute -top-1">
                  <Image src={item.icon} alt="icon" aria-hidden className="ani-child ani-left" />
                </div>
              )}
              <div className="ml-5 pl-6">
                <h3 className="block font-bold mt-1 mb-5 text-xl dark:text-text-bg leading-medium">{item.title}</h3>
                <div className="font-inter leading-150">
                  <MarkdownComponent
                    externalComponents={{
                      p: ({ children }) => (
                        <p className="mb-5 font-light text-base text-text dark:text-opacity-70 dark:text-text-dm">
                          {children}
                        </p>
                      ),
                      strong: ({ children }) => (
                        <strong className="mb-5 font-bold text-base font-bold text-text dark:text-text-bg">
                          {children}
                        </strong>
                      ),
                    }}
                  >
                    {item.description}
                  </MarkdownComponent>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const IconListQuery = graphql`
  fragment IconList on MarkdownRemarkFrontmatterBody {
    icon_list {
      title
      description
      icon {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 35)
        }
        extension
        publicURL
      }
    }
  }
`
