import { graphql } from 'gatsby'
import React, { useState, useMemo } from 'react'

import { FilterControllers } from '@/components'
import { Content, Subheading, Text } from '@/uikit'

import { JobsList } from './components'
import generateOptionsFromJobs from './utils'

type IProps = GatsbyTypes.JobOffersSectionFragment & GatsbyTypes.GreenhouseJobsFragment

type Job = {
  readonly node: Pick<GatsbyTypes.GreenhouseJob, 'title' | 'updated_at' | 'gh_Id'> & {
    readonly location: GatsbyTypes.Maybe<Pick<GatsbyTypes.GreenhouseJobLocation, 'name'>>
    readonly departments: GatsbyTypes.Maybe<ReadonlyArray<Pick<GatsbyTypes.GreenhouseDepartment, 'name'>>>
  }
}
export const JobOffersSection: React.FC<IProps> = ({ heading, description, jobs, jobs_input_placecholder }) => {
  const [searchValue, setSearchValue] = useState('')
  const [option, setOption] = useState('')
  const options = useMemo(() => generateOptionsFromJobs(jobs), [jobs])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const selectFilter = (value: string) => {
    setOption(value)
  }

  const isSearchedResult = (title: string) => title.toLowerCase().includes(searchValue.toLowerCase())

  const isFilteredResult = (job: Job) =>
    !option ? true : job.node.departments?.[0].name.toLowerCase().replaceAll(' ', '-') === option

  const displayedResults = jobs.filter((job) => isSearchedResult(job.node.title || '') && isFilteredResult(job))

  return (
    <section id="jobs" className="pt-65 pb-65">
      <Content className="max-w-770 text-center">
        <div className="mb-7.5">{heading && <Subheading bold>{heading}</Subheading>}</div>
        <div>
          <Text>{description}</Text>
        </div>
      </Content>
      <Content>
        <div className="mb-16.5 md:px-0 max-w-610 lg:max-w-930 mx-auto">
          <FilterControllers
            search
            options={options}
            onSelect={selectFilter}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            activeKey={option}
            placeholder={jobs_input_placecholder}
          />
        </div>
      </Content>
      <JobsList jobs={displayedResults} />
    </section>
  )
}

export const jobOffersSectionQuery = graphql`
  fragment GreenhouseJobs on GreenhouseJobConnection {
    jobs: edges {
      node {
        title
        updated_at(fromNow: true)
        gh_Id
        location {
          name
        }
        departments {
          name
        }
      }
    }
  }
  fragment JobOffersSection on MarkdownRemarkFrontmatterBody {
    heading
    description
    jobs_input_placecholder
  }
`
