import React from 'react'

export const Heading: React.FC<{ children: string }> = ({ children }) => {
  return (
    <h1 className=" leading-2xl md:leading-2xl text-4xl font-ilisarniq font-bold md:text-5xl dark:text-gray-darker dark:duration-500 duration-500 tracking-tightest ">
      {children}
    </h1>
  )
}

export default Heading
