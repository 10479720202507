import React from 'react'

import { ButtonArrowWrap } from './buttonArrow'

interface ButtonProps {
  onClick?: () => void | Promise<void>
  disabled?: boolean
  hasArrow?: boolean
  type?: 'button' | 'submit' | 'reset'
  children: string
  className?: string
}

export const ButtonSecondary: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  hasArrow = true,
  className = 'duration-300 w-full inline-flex h-12 items-center justify-center font-ilisarniq font-bold py-2 px-8 border-2 border-blue shadow-sm text-base rounded-3xl text-black bg-transparent focus:outline-none focus:ring-4 dark:text-white md:py-3 text-text hover:border-orange',
  children,
  type = 'button',
}) => {
  return (
    <button aria-label={children} onClick={onClick} type={type} disabled={disabled} className={`group ${className}`}>
      {hasArrow ? <ButtonArrowWrap children={children} arrowClassName="group-hover:text-orange" /> : <>{children}</>}
    </button>
  )
}
