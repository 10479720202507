import React from 'react'

import { SearchIcon } from '@/icons'
import { FormField, Select } from '@/uikit'

type Option = {
  label: string
  value: string
}

type IProps = {
  activeKey?: string
  options: Option[]
  search?: boolean
  sort?: boolean
  searchValue?: string
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSortChange?: (value: string) => void
  sortOptions?: Option[]
  onSelect: (value: string) => void
  placeholder?: string
}

export const generateOptionsFromValues = (values: string[]): Option[] =>
  values
    .filter((value) => !!value)
    .map((value = '') => ({
      value: value?.toLowerCase()?.replace(/ /g, '-'),
      label: value,
    }))

export const FilterControllers: React.FC<IProps> = ({
  activeKey,
  options,
  search = true,
  sort = false,
  onSearchChange,
  searchValue = '',
  onSortChange,
  sortOptions,
  onSelect,
  placeholder,
}) => {
  return (
    <div className="my-10">
      <div className={`flex ${!sort ? 'flex-col-reverse' : ''} md:flex-row justify-between items-center`}>
        <ul className="hidden lg:flex">
          {options &&
            options.map(({ label, value }) => (
              <li
                key={value}
                className={`transition font-ilisarniq py-4 px-1 cursor-pointer min-w-max mr-7 dark:text-gray-darker dark:text-opacity-70 dark:hover:text-blue-light hover:text-blue-dark border-b-2 border-transparent hover:border-primary hover:opacity-50 ${
                  activeKey === value &&
                  'text-blue-dark dark:text-blue-light mr-7 border-b-2 border-primary dark:border-blue-light dark:text-opacity-80'
                }`}
                onClick={() => onSelect && onSelect(value)}
              >
                {label}
              </li>
            ))}
        </ul>
        <div className={`lg:hidden flex items-start ${!sort ? 'w-full mt-10' : ''}`}>
          <Select id="categories" name="categories" data={options} onChange={onSelect} value={activeKey} secondary />
        </div>
        <div className={`${!sort ? 'w-full' : ''}`}>
          {search && (
            <div className="flex-1 lg:mt-0 mt-10">
              <FormField
                secondaryVariant
                id="search"
                value={searchValue}
                onChange={onSearchChange}
                placeholder={placeholder}
              >
                <SearchIcon />
              </FormField>
            </div>
          )}
          {sort && <Select id="sort" name="sort" data={sortOptions} onChange={onSortChange} />}
        </div>
      </div>
    </div>
  )
}
