import React, { Dispatch, useEffect } from 'react'

import { LOCALSTORAGE_THEME_KEY, themes } from '@/consts'

import { setTheme } from './actions'
import { themeReducer } from './reducer'
import { State, Action, THEMES } from './types'

export const INITIAL_STATE = {
  theme: THEMES.light,
}

export const ThemeContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: INITIAL_STATE,
  dispatch: () => null,
})

export const ThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(themeReducer, INITIAL_STATE)

  useEffect(() => {
    const themeFromLocalStorage: THEMES = localStorage.theme
    if (
      themeFromLocalStorage === themes.dark ||
      !(
        LOCALSTORAGE_THEME_KEY in localStorage
      ) /* uncomment to use user's preference: && window.matchMedia('(prefers-color-scheme: dark)').matches */
    ) {
      dispatch(setTheme(themes.dark))
    } else {
      dispatch(setTheme(themes.light))
    }
  }, [])

  return <ThemeContext.Provider value={{ state, dispatch }}>{children}</ThemeContext.Provider>
}
