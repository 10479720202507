import { themes } from '@/consts'
import { handleGlobalThemeChange } from '@/helpers'

import { State, Action, ACTIONS } from './types'

export function themeReducer(state: State, action: Action) {
  switch (action.type) {
    case ACTIONS.toggle: {
      handleGlobalThemeChange(state.theme === themes.light)

      return {
        theme: state.theme === themes.light ? themes.dark : themes.light,
      }
    }
    case ACTIONS.setTheme: {
      handleGlobalThemeChange(action.payload === themes.dark)

      return {
        theme: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
