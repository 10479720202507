import { Link } from 'gatsby'
import React from 'react'

import { Arrow } from '@/components'
import { Content } from '@/uikit'

type Props = GatsbyTypes.GreenhouseJobsFragment

export const JobsList: React.FC<Props> = ({ jobs }) => {
  return (
    <Content>
      <div className="flex items-center justify-center">
        {jobs.length === 0 && (
          <h2 className="dark:text-white-bg-35 mb-15 font-inter font-bold text-text-80 text-base leading-small md:mb-8.5 text-center w-full">
            No Results Found
          </h2>
        )}
        <ul
          data-ani-threshold="0"
          className="ani-group md:grid md:grid-cols-2 md:gap-7.5 sxl:grid-cols-3 w-full md:w-auto "
        >
          {jobs.map(({ node }) => (
            <li
              key={node.gh_Id}
              className="ani-child ani-up flex-1 flex mb-7.5 w-full md:w-290 max-w-full md:max-w-290 md:mb-0"
            >
              <Link
                to={`/job/${node?.gh_Id}`}
                className="transform-gpu group w-full flex flex-col font-light text-text-80 bg-white dark:bg-body-bg1-dm block px-7.5 py-7.5 rounded-lg duration-500 hover:shadow-lg dark:hover:bg-body-bg2-dm"
              >
                <div className="max-w-230 w-full mb-2">
                  <b className="max-w-230 w-full dark:text-white">{node.title}</b>
                </div>
                <p className="dark:text-white-bg-35 mb-15 font-inter text-base leading-small md:mb-8.5">
                  {node.updated_at}
                </p>
                <p className="dark:text-white-bg-35 mb-15 font-inter text-base leading-small md:mb-8.5">
                  {node.location?.name}
                </p>
                <span className="flex justify-between mt-auto block items-center dark:text-blue-light text-blue-dark">
                  <p className="text-lg leading-medium font-ilisarniq font-normal">Details</p>
                  <Arrow className="transition transform group-hover:translate-x-2" />
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Content>
  )
}
