import { useState } from 'react'

import { validateEmail } from '@/helpers'

type Errors = { email?: string; checkbox?: string; submit?: string }

type UseNewsletterFormState = {
  email: string
  checked: boolean
  hutk: string
  pageUri: string
  pageName: string
  submitting: boolean
  errors: Errors
}

type UseNewsletterForm = {
  formState: UseNewsletterFormState
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onAnalyticsInit: (key: string, val: string) => void
  displayError: (fieldName: string, message: string) => void
  displayErrors: (errors: Errors) => void
  validate: () => boolean
  resetErrors: () => void
  changeSubmittingStatus: () => void
}

const useNewsletterForm = (): UseNewsletterForm => {
  const [formState, changeFormState] = useState({
    email: '',
    checked: false,
    hutk: '',
    pageUri: '',
    pageName: '',
    submitting: false,
    errors: {
      email: '',
      checkbox: '',
      submit: '',
    },
  })

  function onAnalyticsInit(key: string, val: string) {
    changeFormState((prevState) => ({
      ...prevState,
      [key]: val,
    }))
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    changeFormState((prevState) => ({
      ...prevState,
      email: event?.target?.value,
      errors: {
        ...prevState.errors,
        email: '',
      },
    }))
  }

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    changeFormState((prevState) => ({
      ...prevState,
      checked: event.target.checked,
      errors: {
        ...prevState.errors,
        checkbox: '',
      },
    }))
  }

  function displayError(fieldName: string, message: string) {
    changeFormState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldName]: message,
      },
    }))
  }

  function displayErrors(errors: Errors) {
    changeFormState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...errors,
      },
    }))
  }

  function resetErrors() {
    changeFormState((prevState) => ({
      ...prevState,
      errors: {
        email: '',
        checkbox: '',
        submit: '',
      },
    }))
  }

  function changeSubmittingStatus() {
    changeFormState((prevState) => ({
      ...prevState,
      submitting: !prevState.submitting,
    }))
  }

  function validate() {
    const { email, checked } = formState
    const errors: Errors = {
      email: '',
      checkbox: '',
    }
    let isValid = true

    if (!checked) {
      errors.checkbox = 'Please check the option'
      isValid = false
    }
    if (!validateEmail(email)) {
      errors.email = 'Please provide valid Email Address'
      isValid = false
    }

    displayErrors(errors)

    return isValid
  }

  return {
    formState,
    onInputChange,
    onCheckboxChange,
    onAnalyticsInit,
    displayError,
    displayErrors,
    resetErrors,
    changeSubmittingStatus,
    validate,
  }
}

export default useNewsletterForm
