import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { FileNode, IGatsbyImageDataParent } from 'gatsby-plugin-image/dist/src/components/hooks'
import React from 'react'

type ImageDataLike = FileNode | IGatsbyImageDataParent | IGatsbyImageData

type SrcType = {
  extension?: string
  publicURL?: string
  childImageSharp?: ImageDataLike | GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
}
type Props = {
  src?: SrcType
  alt?: string
  className?: string
  width?: string
  height?: string
  objectFit?: string
  style?: object
}

export const Image: React.FunctionComponent<Props> = ({ src, alt, className, width, height, objectFit, style }) => {
  if (!src) return <p>No src</p>

  if (src.extension === 'svg' || src.extension === 'gif')
    return <img src={src.publicURL} style={style} alt={alt} className={className} width={width} height={height} />

  const image = src.childImageSharp && getImage(src.childImageSharp)

  return image ? (
    <GatsbyImage image={image} style={style} alt={alt} className={className} objectFit={objectFit} />
  ) : (
    <p>No data</p>
  )
}
