import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext } from 'react'

import '@/styles/global.css'
import { ViewportAnimationClasses } from '@/animations'
import { themes } from '@/consts'
import { ThemeContext } from '@/context'
import { useMatchMedia } from '@/hooks'
import { Header, Footer, MobileMenu } from '@/partials'
import { breakpoints } from '@/styles/helpers/breakpoints'
import { Image } from '@/uikit'

import { useLocation } from '@reach/router'

import * as style from './styles.module.css'

const MainLayout: React.FC = ({ children }) => {
  const data = useStaticQuery<GatsbyTypes.LayoutQueryQuery>(graphql`
    query LayoutQuery {
      header: markdownRemark(fileAbsolutePath: { regex: "/partials/header.md/" }) {
        frontmatter {
          ...Header
        }
      }
      footer: markdownRemark(fileAbsolutePath: { regex: "/partials/footer.md/" }) {
        frontmatter {
          ...Footer
        }
      }
      accentGraphics: markdownRemark(fileAbsolutePath: { regex: "/partials/accent-graphics.md/" }) {
        frontmatter {
          image_dark_variant {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 705, formats: [WEBP])
            }
            extension
            publicURL
          }
          image_dark_variant_mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
            extension
            publicURL
          }
          image_light_variant {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 705, formats: [WEBP])
            }
            extension
            publicURL
          }
          image_light_variant_mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
            extension
            publicURL
          }
          list_of_pages
        }
      }
    }
  `)
  const { state } = useContext(ThemeContext)
  const isMobileUp = useMatchMedia(`(max-width: ${breakpoints.sm})`)
  const { header, footer, accentGraphics } = data || {}

  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const shouldAccentAppear =
    accentGraphics?.frontmatter?.list_of_pages?.filter((item) => {
      const pathnameNoTrailingSlash = pathname.replace(/\/$/, '')
      const correctPath = pathnameNoTrailingSlash === '/' ? '/homepage' : pathnameNoTrailingSlash
      return item.includes(correctPath)
    }) || []

  const getAccentSrc = () => {
    if (state.theme === themes.dark) {
      if (isMobileUp) {
        return accentGraphics?.frontmatter?.image_dark_variant_mobile
      }
      return accentGraphics?.frontmatter?.image_dark_variant
    }
    if (isMobileUp) {
      return accentGraphics?.frontmatter?.image_light_variant_mobile
    }
    return accentGraphics?.frontmatter?.image_light_variant
  }

  const accentSrc = getAccentSrc()

  const isJob = useLocation().pathname.startsWith('/job/')

  return (
    <div
      className={` ${state.theme === themes.dark || style.gradient} ${
        isJob ? '' : 'overflow-hidden'
      } dark:bg-body-bg2-dm`}
    >
      <ViewportAnimationClasses selector=".ani, .ani-group" />
      <Header {...header?.frontmatter} />
      <main className="bg-body dark:border-white-bg-10 border-t border-b dark:bg-body-dm dark:duration-500 duration-500 rounded-3xl print:rounded-none">
        {children}
        {shouldAccentAppear.length > 0 && (
          <div className="relative top-2.5">
            <Image src={accentSrc} className="ani ani-fade ani-right" alt="additional accent" />
          </div>
        )}
      </main>
      <Footer {...footer?.frontmatter} />
      <MobileMenu {...header?.frontmatter} />
    </div>
  )
}

export default MainLayout
