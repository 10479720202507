import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { sendRateCardForm } from '@/helpers'
import { FormField, ButtonPrimmary, Subheading, Text, Image, Content } from '@/uikit'

import useRateCardForm from './useRateCardForm'

export const RateCardForm: React.FC<GatsbyTypes.RateCardFormFragment> = ({
  rate_card_form_heading,
  rate_card_form_description,
  rate_card_form_title,
  rate_card_form_fee,
  rate_card_form_period,
  rate_card_form_form_title,
  rate_card_form_field_name,
  rate_card_form_field_phone,
  rate_card_form_field_company,
  rate_card_form_field_email,
  rate_card_form_field_description,
  rate_card_form_field_submit_button,
  rate_card_form_alternative_image_text,
  rate_card_form_image_light_mode,
  rate_card_form_image_dark_mode,
}) => {
  const { formState, onInputChange, onAnalyticsInit, validate, resetErrors, displayError, changeSubmittingStatus } =
    useRateCardForm()

  const { name, phone, company, email, message, status, submitting, errors, hutk, pageUri, pageName } = formState

  async function sendForm() {
    sendRateCardForm({ name, phone, company, email, message, hutk, pageUri, pageName })
      .then((data) => {
        const response = JSON.parse(data)
        if (response?.inlineMessage) {
          navigate('/rate-card-message-sent')
        } else {
          displayError('submit', `Error: ${response?.message}`)
          console.error(response?.message, response?.errors)
        }
        changeSubmittingStatus()
      })
      .catch((data) => {
        displayError('submit', 'There was an error submitting the form')
        changeSubmittingStatus()
      })
  }

  const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault()
    resetErrors()
    const isValid = validate()

    if (isValid) {
      sendForm()
    }
  }

  useEffect(() => {
    onAnalyticsInit('hutk', document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1'))
    onAnalyticsInit('pageUri', document.location.href)
    onAnalyticsInit('pageName', document.location.pathname)
  }, [])

  return (
    <div className="mx-auto max-w-1250 print:hidden">
      {1 === 1 && (
        <Content className="max-w-770">
          {rate_card_form_heading && (
            <div className="mb-8 md:text-center">
              <Subheading bold>{rate_card_form_heading}</Subheading>
            </div>
          )}
          {rate_card_form_description && (
            <div className="md:text-center">
              <Text>{rate_card_form_description}</Text>
            </div>
          )}
        </Content>
      )}
      <div className="relative max-w-col mx-auto dark:text-text-dm mt-15.5 md:mt-26">
        <div className="bg-blue-lighter dark:bg-text-12 rounded-t-2xl p-7.5 pt-12 flex justify-center">
          <div className="max-w-1060 relative z-10 sm:w-2/3 mr-auto">
            {rate_card_form_title && <h2 className="font-semibold">{rate_card_form_title}</h2>}
            {rate_card_form_fee && (
              <p className="text-orange text-40 font-bold font-ilisarniq mt-3">{rate_card_form_fee}</p>
            )}
            {rate_card_form_period && <p className="sm:max-w-370">{rate_card_form_period}</p>}
          </div>
        </div>
        <form
          onSubmit={onSubmit}
          className="bg-blue-darker dark:bg-body-bg1-dm rounded-b-2xl p-7.5 flex justify-center sm:relative"
        >
          <div className="max-w-1060 w-full">
            <div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:max-w-610 relative z-10 md:pt-5">
              {rate_card_form_form_title && (
                <h3 className="md:col-span-2 font-semibold text-lg">{rate_card_form_form_title}</h3>
              )}
              <FormField
                placeholder={rate_card_form_field_name}
                onChange={onInputChange}
                value={name}
                id="rate-card-form-name"
                name="name"
                error={errors.name}
              />
              <FormField
                placeholder={rate_card_form_field_email}
                onChange={onInputChange}
                value={email}
                id="rate-card-form-email"
                name="email"
                error={errors.email}
              />
              <FormField
                placeholder={rate_card_form_field_company}
                onChange={onInputChange}
                value={company}
                id="rate-card-form-company"
                name="company"
                error={errors.company}
              />
              <FormField
                placeholder={rate_card_form_field_phone}
                onChange={onInputChange}
                value={phone}
                id="rate-card-form-phone"
                name="phone"
                error={errors.phone}
              />
              <FormField
                placeholder={rate_card_form_field_description}
                onChange={onInputChange}
                value={message}
                id="rate-card-form-message"
                name="message"
                error={errors.message}
                className="md:col-span-2 mb-5"
              />
              <ButtonPrimmary type="submit" disabled={submitting} hasArrow={false}>
                {rate_card_form_field_submit_button}
              </ButtonPrimmary>
            </div>
            {rate_card_form_image_light_mode && (
              <div className="max-w-4/10 absolute -right-15 lg:right-0 top-0 transform -translate-y-1/2 sm:-translate-y-2/3 lg:-translate-y-1/3 dark:hidden">
                <Image
                  className="ani ani-up"
                  src={rate_card_form_image_light_mode}
                  alt={rate_card_form_alternative_image_text}
                />
              </div>
            )}
            {rate_card_form_image_dark_mode && (
              <div className="max-w-4/10 absolute -right-15 lg:right-0 top-0 transform -translate-y-1/2 sm:-translate-y-2/3 lg:-translate-y-1/3 dark:block hidden">
                <Image
                  className="ani ani-up"
                  src={rate_card_form_image_dark_mode}
                  alt={rate_card_form_alternative_image_text}
                />
              </div>
            )}
            {errors.submit && (
              <p className="pl-4 mt-4 font-inter font-normal text-red text-opacity-50">{errors.submit}</p>
            )}
            {status && <p className="font-inter text-text-70 dark:text-text-70-dm mt-5">{status}</p>}
          </div>
        </form>
      </div>
    </div>
  )
}

export const RateCardFormQuery = graphql`
  fragment RateCardForm on MarkdownRemarkFrontmatterBody {
    rate_card_form_heading
    rate_card_form_description
    rate_card_form_title
    rate_card_form_fee
    rate_card_form_period
    rate_card_form_form_title
    rate_card_form_field_name
    rate_card_form_field_phone
    rate_card_form_field_company
    rate_card_form_field_email
    rate_card_form_field_description
    rate_card_form_field_submit_button
    rate_card_form_alternative_image_text
    rate_card_form_image_light_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 423, formats: [WEBP])
      }
      extension
      publicURL
    }
    rate_card_form_image_dark_mode {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 423, formats: [WEBP])
      }
      extension
      publicURL
    }
  }
`
