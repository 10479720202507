import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'

type Props = {
  url: string
  type: 'internal' | 'external'
  title?: string
  newTab: boolean
  children: React.ReactChild
  className?: string
  activeClassName?: string
}

export const CustomLink: React.FC<Props> = ({
  url,
  type,
  title,
  newTab,
  children,
  activeClassName = '',
  className = '',
}) => {
  const [anchorTarget, setAnchorTarget] = useState(null)

  useEffect(() => {
    if (url.startsWith('#')) {
      setAnchorTarget(document.getElementById(url.split('#')[1]))
    }
  }, [])

  const handleClick = (e) => {
    if (anchorTarget) {
      e.preventDefault()
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  if (type === 'internal')
    return (
      <Link to={url} activeClassName={activeClassName} className={className} title={title} onClick={handleClick}>
        {children || title}
      </Link>
    )
  if (type === 'external')
    return (
      <a href={url} rel="noreferrer" target={newTab ? '_blank' : ''} className={className} title={title}>
        {children}
      </a>
    )
  return <p>UNKNOWN LINK TYPE</p>
}
