import { graphql } from 'gatsby'
import React from 'react'

import { StepBox } from '@/components'
import { Label, Text, Subheading, Content } from '@/uikit'

export const HowWeWorkSection: React.FC<GatsbyTypes.HowWeWorkSectionFragment> = ({
  label,
  title,
  description,
  step_blocks,
}) => {
  return (
    <Content>
      <div className="flex flex-col items-center pt-65 pb-65 md:pt-6">
        <div className="mb-2.5">
          <Label>{label}</Label>
        </div>
        <div className="mb-7.5 text-center ">
          <Subheading bold>{title}</Subheading>
        </div>
        <div className="mb-7.5 text-center">
          <Text>{description}</Text>
        </div>
        <ul
          data-ani-stagger="100"
          className="ani-group mt-10 w-full flex-col lg:flex-row flex justify-between z-10 md:max-w-930 list-none"
        >
          {step_blocks && step_blocks?.map((box) => <StepBox {...box} key={box.heading} />)}
        </ul>
      </div>
    </Content>
  )
}

export const howWeWorkSectionQuery = graphql`
  fragment HowWeWorkSection on MarkdownRemarkFrontmatterBody {
    label
    title
    description
    step_blocks {
      slug
      heading
      description
      icon {
        extension
        publicURL
      }
      icon_dark_mode {
        extension
        publicURL
      }
      icon_alternative_text
    }
  }
`
