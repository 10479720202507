import { Link } from 'gatsby'
import React from 'react'

import { capitalizeFirstLetter, formatDate } from '@/helpers/utils'
import { Image } from '@/uikit'

type Props = Pick<
  GatsbyTypes.MarkdownRemarkFrontmatter,
  'url' | 'title' | 'author' | 'date_of_publication' | 'post_categories' | 'excerpt'
> & {
  readonly bp_image_light: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
  readonly bp_image_dark: GatsbyTypes.Maybe<{
    readonly childImageSharp: GatsbyTypes.Maybe<Pick<GatsbyTypes.ImageSharp, 'gatsbyImageData'>>
  }>
}

export const Post: React.FC<Props> = ({
  post_categories,
  url,
  bp_image_light,
  bp_image_dark,
  date_of_publication,
  author,
  title,
}) => {
  const parsedPostCategories = post_categories?.map((item) => capitalizeFirstLetter(item)).join(' | ')

  return (
    <Link to={`/blog/${url}`} className="mb-15.5 block md:w-1/2 md:mb-30 md:max-w-1/3 group">
      <div className="bg-body-bg2 dark:bg-body-dm rounded-semiXl ">
        <div className="h-48 bg-body-bg2 dark:bg-body-bg2-dm max-w-full rounded-semiXl items-stretch justify-center overflow-hidden">
          {bp_image_light && (
            <Image
              src={bp_image_light}
              alt={title}
              className="dark:hidden h-full block rounded-semiXl object-cover transition-transform duration-500 hover:scale-105 transform"
            />
          )}
          {bp_image_dark && (
            <Image
              src={bp_image_dark}
              alt={title}
              className="dark:flex hidden h-full block rounded-semiXl object-cover transition-transform duration-500 hover:scale-105 transform"
            />
          )}
        </div>
      </div>
      <div className="flex-initial">
        <div className="text-black-lighter dark:text-text-70-dm font-inter font-light leading-5 my-4">
          {formatDate(date_of_publication)} | {parsedPostCategories} | {author}
        </div>
        <div className="font-semibold font-inter">
          <h3 className="leading-medium text-lg text-black-lighter dark:text-white dark:group-hover:text-blue-light group-hover:text-blue transition-colors">
            {title}
          </h3>
        </div>
      </div>
    </Link>
  )
}
