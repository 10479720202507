import { graphql } from 'gatsby'
import React from 'react'

import { Content, Subheading, Text } from '@/uikit'

import * as styles from './styles.module.css'

export const CoreValuesSection: React.FC<GatsbyTypes.CoreValuesSectionFragment> = ({
  header,
  lead,
  alternative_icon_text,
  icon,
  values,
}) => {
  return (
    <div className="pt-65 pb-65 md:pt-77">
      <Content>
        <div className="bg-white dark:bg-body-bg1-dm rounded-2xl flex items-center justify-center flex-col pt-20 md:pt-25 sm:pb-13.5 pb-5.2 px-5.2 md:px-13">
          <div className="max-w-740 flex justify-center items-center flex-col">
            <div className="max-w-130 mb-5 md:mb-10.5">
              <img src={icon?.publicURL} alt={alternative_icon_text} className={styles.icon} height="43" width="50" />
            </div>
            <div className="mb-10 md:mb-11 leading-2xl not-italic text-center">
              {header && <Subheading bold>{header}</Subheading>}
            </div>
            <div className="mb-10 max-w-270 xmd:max-w-full md:mb-11 leading-150 text-center">
              <Text>{lead}</Text>
            </div>
          </div>
          <ul className="ani-group grid md:grid-cols-3 gap-5.2 sm:gap-10 max-w-1150 justify-center md:justify-between md:flex-row flex-col md:items-stretch items-center w-full">
            {values &&
              values.map((coreValue) => (
                <li
                  key={coreValue?.heading}
                  className="ani-child ani-up max-w-350 md:pt-8 md:pb-10 flex-1 flex justify-start items-start flex-row md:flex-col bg-noise bg-body-bg2 dark:bg-body-dm px-5 py-10 md:px-7.5 w-full rounded-2xl"
                >
                  <div className="mb-2 mt-0.5 md:mt-0 md:max-w-full max-w-6.5 w-full">
                    <img
                      src={coreValue.icon?.publicURL}
                      alt={coreValue.alternative_icon_text}
                      className={styles.icon_box}
                      height="40"
                      width="40"
                    />
                  </div>
                  <div className="ml-8 md:ml-0">
                    <div className="mb-2">
                      <b className="mb-4 md:mb-2.5 dark:text-text-dm">{coreValue.heading}</b>
                    </div>
                    <p className="font-inter font-light text-lg text-text-70 dark:text-text-70-dm leading-150">
                      {coreValue.description}
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </Content>
    </div>
  )
}

export const coreValuesSectionQuery = graphql`
  fragment CoreValuesSection on MarkdownRemarkFrontmatterBody {
    header
    lead
    icon {
      extension
      publicURL
    }
    alternative_icon_text
    values {
      icon {
        extension
        publicURL
      }
      alternative_icon_text
      heading
      description
    }
  }
`
