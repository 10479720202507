import React from 'react'

export const ButtonArrowWrap: React.FC = ({ children, arrowClassName }) => {
  return (
    <span className="duration-300 transform group-hover:-translate-x-4 flex items-center transform-gpu">
      {children}
      <svg
        className={`duration-300 absolute top-1 transform group-hover:translate-x-6 translate-x-6 right-0 text-transparent ${arrowClassName}`}
        width="11"
        height="19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.833 1.9l7.334 7.6-7.334 7.6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
